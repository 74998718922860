import 'react-app-polyfill/ie11';
import 'jquery/src/jquery';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


import './assets/js/mainmenu/menu.css';
import './assets/css/default.css';
import './assets/css/layouts.css';
import './assets/js/smart-forms/smart-forms.css';
import './assets/css/custom.css';
import './assets/css/shortcodes.css';
import './assets/css/font-awesome/css/font-awesome.min.css';
import './assets/css/responsive-leyouts.css';
import './assets/css/Simple-Line-Icons-Webfont/simple-line-icons.css';
import './assets/css/et-line-font/et-line-font.css';
import './assets/css/colors/orange.css';


import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import  Store  from './store/index';

Sentry.init({dsn: "https://d55ee3a3937f468784738e25d415cd11@sentry.io/1795453"});


ReactDOM.render(
    <Provider store={Store}>
      <App />
    </Provider>
  , document.getElementById('root'));


serviceWorker.unregister();
