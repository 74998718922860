import React, { useState, useReducer } from 'react'; 

import XLSX from 'xlsx'; 
import api from '../../services/api';


function InvestmentPolicy(){ 
    const dateRegex = /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/

    const indexadores = [
        'SHR','AFN','ANB','BDR','SB1','SB2','CDI','DOL','DOW','EUR','FG1','FGE','IBA','IBO','IBr','IBX','IB5','ICB','ICO','ICN','ICV','IDA','IDD','IDG','IDP','IDM','IDC','IEI','IDI','IDE','IDV','I10','I15','I20','I01','I30','I03','I05','ID1','ID2','ID3','I3M','ID5','IEE','JPY','IFX','IFM','IMM','IFN','IGC','IGN','IGT','IGX','IGI','IG1','IGD','IGM','IHF','IMG','IGE','IMB','IB1','IMA','IB2','IMC','IC1','IC2','IMS','IMT','IMO','INC','SPB','SPM','SPR','SPQ','SPA','SPI','IND','INP','INS','IPA','IPC','ICA','IP1','IPE','IPI','IPB','IPF','IRM','IRF','IR1','IM1','ISE','ITA','ITE','IVB','MLC','MSB','MSW','OZ1','MXN','POU','PRE','PTA','SPP','SPC','SPJ','SAL','SCC','SCS','SEL','SM','SML','TER','TBF','BSR','TPN','TJL','TR','UTI'
    ]   

    const headerList = [
        'cnpj', 'codCart', 'dtPosicao'
    ]
    const indexTaxList = [
        'participacao', 'tipoTaxaIndice', 'indexador', 'percIndex', 'txOperacao'
    ]
    const benefitList = [
        'cnpb', 'ativosDaPatrocinadora', 'metaDeRentabilidade','rentabilidadeAcumulada', 'metodologiaRentabilidade'
    ]
    const rentalList = [
        'ano', 'valorRentabilidade'
    ]
    const segmentsList = [
        'tipoSegmento', 'limiteMinimo', 'previsaoDeAlocacao', 'limiteMaximo','metaDeRentabilidadeSegmento', 'rentabilidadeAcumuladaSegmento'
    ]
    const rentalSegmentList = [
        'anoSegmento', 'valorRentabilidadeSegmento'
    ]

    const changeSegmentType = (value) => {
        switch(value){
            case '1':
                return 'Renda Fixa'
            case '2':
                return 'Renda Variável'
            case '3':
                return 'Estruturado'
            case '4':
                return 'Imobiliário'
            case '5':
                return 'Operações com Participantes'
            case '6':
                return 'Exterior'
            default:
                return null;
        }
    }

    const changeMetodology = (value) => {
        switch(value){
            case '1':
                return 'TIR'
            case '2':
                return 'Cotização Adaptada'
            case '3':
                return 'Fluxo Médio'
            case '4':
                return 'Outras'
            default:
                return null;
        }
    }

    const [headerInput, setHeaderInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        {     
          cnpj: '', 
          codCart: '', 
          dtPosicao: '', 
        } 
      );
      
    const [indexTaxInput, setIndexTaxInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        { 
            participacao: '',
            tipoTaxaIndice: '0',
            indexador: '',
            percIndex: '',
            txOperacao: ''
        }
    );

    const [rentalInput, setRentalInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        { 
            ano: '',
            valorRentabilidade: '',
        }
    );

    const [benefitInput, setBenefitInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        {   
          cnpb: '', 
          ativosDaPatrocinadora: '', 
          metaDeRentabilidade: '', 
          rentabilidadeAcumulada: '',  
          metodologiaRentabilidade: '0',

          rentabilidadeAuferida:[

          ],

          taxaIndice:[

          ],

          segmentos:[
            
          ]
        } 
    );

    const [rentalSegmentsInput, setRentalSegmentsInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        { 
            anoSegmento: '',
            valorRentabilidadeSegmento: '',
        }
    );

    const[segmentsInput, setSegmentsInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          tipoSegmento: '0',
          limiteMinimo: '',
          previsaoDeAlocacao: '',
          limiteMaximo: '',
          metaDeRentabilidadeSegmento: '',
          rentabilidadeAcumuladaSegmento: '',

          rentabilidadeAuferidaSegmento:[
            
          ],
        }
    )

    const setInputNameValue = (name, newValue) => {
        if(headerList.indexOf(name) >= 0){ 
            setHeaderInput({[name]: newValue}) 
        } 
        if(indexTaxList.indexOf(name) >= 0) {
            setIndexTaxInput({[name]: newValue})
        }  
        if(benefitList.indexOf(name) >= 0){
            setBenefitInput({[name]: newValue})
        }
        if(rentalList.indexOf(name) >= 0){
            setRentalInput({[name]: newValue})
        }
        if(segmentsList.indexOf(name) >= 0){
            setSegmentsInput({[name]: newValue})
        }
        if(rentalSegmentList.indexOf(name) >= 0){
            setRentalSegmentsInput({[name]: newValue})
        }
    }

    const handleInputChange = e => { 
        setInputNameValue(e.target.name, e.target.value)  
    } 

    const handleNumberFields = e => { 
        const re = /^[0-9\b]+$/; 
        if (e.target.value === '' || re.test(e.target.value)) { 
            setInputNameValue(e.target.name, e.target.value) 
        } 
    }

    const handlePercFields = e => {
        const re = /^[-.,0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) { 
            setInputNameValue(e.target.name, e.target.value)  
        }
    }
    
    const handleDateFields = e => {
        const name = e.target.name; 
        const newValue = e.target.value; 
  
        const re = /^[-0-9\b]+$/;
  
        if (newValue === '' || re.test(newValue)) { 
          if(name === 'dtPosicao'){ 
            setHeaderInput({[name]: newValue}) 
          }  
        }
    }

    const validateHeaderForm = () => {
        if(headerInput.codCart.length > 0 || headerInput.cnpj.length > 13 && headerInput.dtPosicao.length > 9){
          if(dateRegex.test(headerInput.dtPosicao)){
            return true
          } else {
            return false
          }
        }
    }

    const sendJson = () => { 
        var header = headerInput
        setObjects([...objects, objects[0].header = header]) 
  
        var jsonData = JSON.stringify(objects) 
        jsonData = jsonData.replace(/[.]/g, ',')
   
        api.post('/xmlPolitInvestimento/', jsonData) 
        .then(resp => {  
            console.log(resp.data.success) 
            var fileName = resp.headers['file-name'] 
            if(window.navigator.msSaveOrOpenBlob) { 
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
            } else { 
              const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
              const link = document.createElement('a'); 
              link.href = url; 
              link.setAttribute('download', fileName);  
              document.body.appendChild(link); 
              link.click(); 
            } 
            
            setHeaderInput(          
                {     
                  cnpj: '', 
                  codCart: '', 
                  dtPosicao: '', 
                } 
              ); 
            setObjects([])
        }) 
        .catch(e => { 
            console.log(e.response.data.error) 
        }); 
   
    };

    function cleanInputs(){  
        setIndexTaxInput({ 
                participacao: '',
                tipoTaxaIndice: '0',
                indexador: '',
                percIndex: '',
                txOperacao: ''
            }); 
   
        setRentalInput({ 
                ano: '',
                valorRentabilidade: '',
            });
    
        setBenefitInput({   
              cnpb: '', 
              ativosDaPatrocinadora: '', 
              metaDeRentabilidade: '', 
              rentabilidadeAcumulada: '',  
              metodologiaRentabilidade: '0',
        })

        setRentalSegmentsInput({ 
             anoSegmento: '',
             valorRentabilidadeSegmento: '',
            }
        );
    
        setSegmentsInput({
              tipoSegmento: '0',
              limiteMinimo: '',
              previsaoDeAlocacao: '',
              limiteMaximo: '',
              metaDeRentabilidadeSegmento: '',
              rentabilidadeAcumuladaSegmento: '',
            }
        )
    }

    const [tableIndex, setTableIndex] = useState(0)
    const [segmentIndex, setSegmentIndex] = useState(0)
    const [objects, setObjects] = useState([]); 
    const handlePushPlanBen = (header, planoDeBeneficios) => { 
      ////////// Fix for I.E /////////
      if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
          value: function(predicate) {
           // 1. Let O be ? ToObject(this value).
            if (this == null) {
              throw new TypeError('"this" is null or not defined');
            }
      
            var o = Object(this);
      
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
      
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
              throw new TypeError('predicate must be a function');
            }
      
            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];
      
            // 5. Let k be 0.
            var k = 0;
      
            // 6. Repeat, while k < len
            while (k < len) {
              // a. Let Pk be ! ToString(k).
              // b. Let kValue be ? Get(O, Pk).
              // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
              // d. If testResult is true, return k.
              var kValue = o[k];
              if (predicate.call(thisArg, kValue, k, o)) {
                return k;
              }
              // e. Increase k by 1.
              k++;
            }
      
            // 7. Return -1.
            return -1;
          },
          configurable: true,
          writable: true
        });
      }
      ////////////////////////////////


      if(objects.findIndex(i => i.header)){ 
        setObjects([...objects,  {header, ['planodebeneficio']: [planoDeBeneficios]}]) 
      } else {
        setObjects([...objects, objects[0].planodebeneficio.push(planoDeBeneficios)]) 
      } 

      setBenefitInput({
        taxaIndice:[

      ]})
      setBenefitInput({
        rentabilidadeAuferida:[

      ]})

      setBenefitInput({
        segmentos:[

      ]})

      objects.splice(1, 1)
      cleanInputs();
    }   

    const handlePushIndex = (index, taxaIndice) => {
        objects.splice(1, 1)
        setObjects([...objects,  objects[0].planodebeneficio[index].taxaIndice.push(taxaIndice)]) 

        cleanInputs();
    }

    const handlePushPlanBenRental = (index, rentabilidadeAuferida) => {
        objects.splice(1, 1)
        setObjects([...objects,  objects[0].planodebeneficio[index].rentabilidadeAuferida.push(rentabilidadeAuferida)]) 

        cleanInputs();
    }

    const handlePushSegments = (index, segmentos) => {   
        objects.splice(1, 1)
        setObjects([...objects,  objects[0].planodebeneficio[index].segmentos.push(segmentos)]) 
  
        setSegmentsInput({
          rentabilidadeAuferidaSegmento:[
  
        ]})

        cleanInputs();
    }

    const handlePushRentalSegments = (index, rentabilidadeAuferidaSegmento) => {
        objects.splice(1, 1)

        setObjects([...objects,  objects[0].planodebeneficio[index].segmentos[segmentIndex].rentabilidadeAuferidaSegmento.push(rentabilidadeAuferidaSegmento)])

        cleanInputs()
    }

    const handleRemoveTaxIndice = (index) => {
        objects[0]['planodebeneficio'][tableIndex].taxaIndice.splice(index, 1)
  
        cleanInputs()
    }

    const handleRemovePlanBen = (index) => {
        objects[0]['planodebeneficio'].splice(index, 1)
  
        cleanInputs()
    }

    const handleRemovePlanBenRental = (index) => {
        objects[0]['planodebeneficio'][tableIndex].rentabilidadeAuferida.splice(index, 1)
  
        cleanInputs()
    }

    const handleRemoveSeg = (index) => {
        objects[0].planodebeneficio[tableIndex].segmentos.splice(index, 1)

        cleanInputs()
    }

    const handleRemoveRentalSeg = (index) => {
        objects[0].planodebeneficio[tableIndex].segmentos[segmentIndex].rentabilidadeAuferidaSegmento.splice(index, 1)

        cleanInputs()
    }

    const jsonFix = (jsonFile) => {
        jsonFile = jsonFile.replace(/\w\d{2}\W\d{2}\W\d{2}\W\d{3}\w/gm, "")
        jsonFile = jsonFile.replace(/"__EMPTY":" ",/g,'')
        jsonFile = jsonFile.replace(/[\/]/g,'')
        jsonFile = jsonFile.replace(/[.]/g,',')

        return jsonFile
    }

    const handleFile = (e) => {
        if(e.target.files.length > 0){ 
            var files = e.target.files, f = files[0]; 
            var reader = new FileReader(); 
            reader.onload = function(e) { 
              var data = new Uint8Array(e.target.result); 
              var workbook = XLSX.read(data, {type: 'array'}) 
             
              var sheet_header = "header" 
              var sheet_planBen = "plano de beneficio"
              var sheet_indicePlanos = "índices dos planos"
              var sheet_segmentos = "segmentos"
              var sheet_perfilInvest = "perfil de investimento"
              var sheet_perfilInvestIndice = "índice do perfil"
              var sheet_perfilInvestSegmentos = "segmentos do perfil"

              var worksheet_header = workbook.Sheets[sheet_header]; 
              var worksheet_planBen = workbook.Sheets[sheet_planBen];
              var worksheet_indicePlanos = workbook.Sheets[sheet_indicePlanos];
              var worksheet_segmentos = workbook.Sheets[sheet_segmentos];
              var worksheet_perfilInvest = workbook.Sheets[sheet_perfilInvest];
              var worksheet_perfilInvesIndice = workbook.Sheets[sheet_perfilInvestIndice];
              var worksheet_perfilInvestSegmentos = workbook.Sheets[sheet_perfilInvestSegmentos];
    
              var headerData = XLSX.utils.sheet_to_json(worksheet_header, {defval: ' '})
              var planBenData = XLSX.utils.sheet_to_json(worksheet_planBen, {defval: ' ', raw: false})
              var indicePlanos = XLSX.utils.sheet_to_json(worksheet_indicePlanos, {defval: ' ', raw: false})
              var segmentosData = XLSX.utils.sheet_to_json(worksheet_segmentos, {defval: ' ', raw: false})
              var perfilInvestData = XLSX.utils.sheet_to_json(worksheet_perfilInvest, {defval: ' ', raw: false})
              var perfilInvestIndiceData = XLSX.utils.sheet_to_json(worksheet_perfilInvesIndice, {defval: ' ', raw: false})
              var perfilInvestSegmentosData = XLSX.utils.sheet_to_json(worksheet_perfilInvestSegmentos, {defval: ' ', raw: false})

              var PolitInvestData = { ['header']: headerData, 
              ['planodebeneficio']: planBenData, indicePlanos, segmentosData, perfilInvestData, perfilInvestIndiceData, perfilInvestSegmentosData
                  
              } 

              var jsonFile = jsonFix(JSON.stringify(PolitInvestData))

              console.log(jsonFile)


              api.post('/xmlPolitInvestimentoTemplate', jsonFile, {responseType: 'arraybuffer'}) 
              .then(resp => {  
                  console.log(resp.data.success) 
                  var fileName = resp.headers['file-name'] 
                  if(window.navigator.msSaveOrOpenBlob) { 
                    window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
                  } else { 
                    const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
                    const link = document.createElement('a'); 
                    link.href = url; 
                    link.setAttribute('download', fileName);  
                    document.body.appendChild(link); 
                    link.click(); 
                  } 
              }) 
              .catch(e => { 
                    alert("Tente novamente mais tarde...") 
              });
  
              document.getElementById("file-uploader").value = "";
            }; 
        reader.readAsArrayBuffer(f); 
        } 
    }

    const handleTemplateDownload = () => {
        var templateName = 'modelo_politica_investimento'
        api.get(`template/${templateName}`, {responseType: 'arraybuffer'})
        .then(resp => { 
          if(window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${templateName}.xlsx`);
          } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${templateName}.xlsx`); 
              document.body.appendChild(link);
              link.click();
            }
        })
        .catch(e => {
            console.log(e);
        }); 
    }

    return ( 
    <div> 
        <div className="panel panel-default"> 
            <div className="panel-heading clearfix"> 
                <h3 className="panel-title pull-left"> Informe os dados da Entidade </h3> 
                <div className="row">
                    <span className="help-block pull-right" style={{marginRight:'30px', color:'red'}}> Planilha Atualizada 27/02/2023</span>
                </div>   
                <button className="btn btn-default btn-lg pull-right" onClick={() => {handleTemplateDownload()}}>
                    <span className="fa fa-file-excel-o fa-1x align-left" style={{color:'green'}}>
                    </span> Download Template
                </button>       
            </div> 
            <div className="panel-body"> 
                <form> 
                <div className={headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ? 'form-group col-md-4 has-error' : 'form-group col-md-4'}> 
                        <label className="control-label">CNPJ</label> 
                        <input  
                        disabled="disabled"
                        type="text"  
                        name="cnpj" 
                        value={headerInput.cnpj} 
                        className="form-control" 
                        placeholder="CNPJ do titular" 
                        maxLength="14" 
                        onChange={handleNumberFields}/>
                        { headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ?
                          <span className="help-block">O CNPJ precisa ter 14 dígitos</span>
                        : null} 
                    </div> 
                    <div className="form-group col-md-4"> 
                        <label>Código da Carteira OU Nome da Entidade</label> 
                        <input  
                        disabled="disabled"
                        type="text" 
                        name="codCart" 
                        placeholder="Código carteira" 
                        value={headerInput.codCart}  
                        className="form-control"  
                        onChange={handleInputChange} /> 
                    </div> 
                    <div className={headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ? "form-group col-md-4 has-error" : "form-group col-md-4"}> 
                        <label className="control-label">Data da Posição</label> 
                        <input type="text" 
                        disabled="disabled"
                        className="form-control"  
                        name="dtPosicao" 
                        value={headerInput.dtPosicao} 
                        placeholder="AAAA-MM-DD" 
                        maxLength="10" 
                        onChange={handleDateFields}/> 
                        { headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ?
                          <span className="help-block">Insira a data no seguinte no formato:  AAAA-MM-DD</span>
                        : null }  
                    </div>
                </form>
            </div>
            <div className="panel-footer">                
            {
                (objects.length > 0 && validateHeaderForm()) ?
                  <button type="button" onClick={sendJson} className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
                    : //Conditional Render
                  <button type="button" disabled className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
            }
            {
                (validateHeaderForm()) ?                     
                  <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Plano de Beneficio</button> 
                  : //Conditional Render           
                  <button type="button" disabled className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Plano de Beneficio</button>
            } 
                <label className="custom-file-upload" title="Para usar esta opção, faça o download do modelo acima. Após preenchido, envie o modelo através deste botão."> 
                    <input id="file-uploader" accept=".xlsx" type="file" onChange={handleFile} /> 
                    <i className="fa fa-upload"></i> Enviar template preenchido 
                </label> 
            </div>
        </div>

        {/* Table for Benefit Plans info */}
        <div className="panel panel-default"> 
            <table className="table table-striped"> 
                <thead className="thead-light"> 
                    <tr> 
                        <th scope="col" className="text-center">Adicionar/Remover Indice e/ou Rentabilidade</th>
                        <th scope="col" className="text-center">CNPB</th> 
                        <th scope="col" className="text-center">Ativos da Patro.</th> 
                        <th scope="col" className="text-center">Meta de Rent.</th> 
                        <th scope="col" className="text-center">Rentabilidade Acumulada</th> 
                        <th scope="col" className="text-center">Metodologia Rent.</th>
                        <th scope="col" className="text-center">Adicionar Segmento</th> 
                        <th scope="col" className="text-center">Visualizar Segmentos</th>
                        <th scope="col" className="text-center">Remover Plano de Benef.</th> 
                        </tr> 
                </thead> 
                <tbody>          
                { objects[0] && objects[0].planodebeneficio.map(function(item, index){ 
                return( 
                    <tr key={index}>  
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" type="button" data-toggle="modal" data-target="#planBenView"
                            title="Visualização Geral"
                            onClick={() => setTableIndex(index)}> 
                            <i className="fa fa-list-alt"></i></button> 
                            </span>
                        </td> 
                        <td className="text-center">{item.cnpb}</td> 
                        <td className="text-center">{item.ativosDaPatrocinadora}</td> 
                        <td className="text-center">{item.metaDeRentabilidade}</td> 
                        <td className="text-center">{item.rentabilidadeAcumulada} %</td> 
                        <td className="text-center">{changeMetodology(item.metodologiaRentabilidade)}</td> 
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" title="Adicionar Segmento" type="button" data-toggle="modal" data-target="#segmentModal" onClick={() => setTableIndex(index)}> 
                            <i className="fa fa-arrows"></i></button> 
                            </span>
                        </td>
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" title="Visualizar 
                                Segmento" data-toggle="modal" data-target="#modalSegmentView" type="button" onClick={() => setTableIndex(index)}> 
                            <i className="fa fa-eye"></i></button> 
                            </span>
                        </td>
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" type="button" title="Remover Plano de Beneficio" onClick={() => handleRemovePlanBen(index)}> 
                            <i className="fa fa-trash"></i></button> 
                            </span>
                        </td> 
                        </tr> 
                        ) 
                    })}        
                </tbody> 
            </table> 
        </div>

        {/* Modal for Benefit Plans register */}
        <div className="modal fade" id="FundoModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                     <div className="modal-header"> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                            <h4 className="modal-title" id="myModalLabel">Adicionar Plano de Beneficio</h4> 
                        </div> 
                        <div className="modal-body-fundo">  
                        <form id="input"> 
                        <div className={benefitInput.cnpb.length >= 1 && benefitInput.cnpb.length < 10 ? 'form-group  has-error' : 'form-group'}>
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label ">CNPB</label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <input 
                                    name="cnpb"  
                                    maxLength="10"  
                                    className="form-control"
                                    placeholder="10 Dígitos"
                                    onChange={handleNumberFields}
                                    value={benefitInput.cnpb}/>
                                    { benefitInput.cnpb.length >= 1 && benefitInput.cnpb.length < 10 ?
                                  <span className="help-block">O CNPB precisa ter 10 dígitos</span>
                                  : null}
                                </div> 
                            </div>
                            </div> 
                            <div className={benefitInput.ativosDaPatrocinadora === '' ? 'form-group  has-error' : 'form-group'}>
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label ">Ativos Da Patrocinadora</label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <div className="input-group">
                                        <input type="text"   
                                        className="form-control"      
                                        name="ativosDaPatrocinadora"
                                        value={benefitInput.ativosDaPatrocinadora}
                                        onChange={handlePercFields}/>
                                        <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                    </div>                                 
                                </div> 
                            </div>
                            </div> 
                            <div className={benefitInput.metaDeRentabilidade === '' ? 'form-group  has-error' : 'form-group'}> 
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label ">Meta de Rentabilidade</label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <div className="input-group">
                                        <input  
                                        name="metaDeRentabilidade"  
                                        className="form-control"
                                        onChange={handlePercFields}
                                        value={benefitInput.metaDeRentabilidade}/> 
                                        <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span> 
                                    </div>
                                </div> 
                            </div>
                            </div>  
                            <div className={benefitInput.rentabilidadeAcumulada === '' ? 'form-group  has-error' : 'form-group'}>
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Rentabilidade Acumulada</label> 
                                </div> 
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <input type="text"                                  
                                        className="form-control" 
                                        name="rentabilidadeAcumulada" 
                                        value={benefitInput.rentabilidadeAcumulada}
                                        onChange={handlePercFields}/> 
                                        <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span> 
                                    </div>
                                </div>
                            </div>  
                            </div>
                            <div className={benefitInput.metodologiaRentabilidade === '0' ? 'form-group  has-error' : 'form-group'}> 
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Metodologia de Rentabilidade</label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <select                                 
                                        className="form-control" 
                                        name="metodologiaRentabilidade" 
                                        value={benefitInput.metodologiaRentabilidade}
                                        onChange={handleNumberFields}>
                                        <option value="" hidden></option> 
                                        <option value="1">TIR</option> 
                                        <option value="2">Cotização Adaptada</option> 
                                        <option value="3">Fluxo Médio</option> 
                                        <option value="4">Outras</option>  
                                    </select>
                                </div>
                            </div>  
                            </div>                           
                        </form>      
                        </div> 
                        <div className="modal-footer">  
                        <button  
                            type="button"  
                            className="btn btn-primary"  
                            data-dismiss="modal"   
                            onClick={() => handlePushPlanBen(headerInput, benefitInput, segmentsInput, rentalSegmentsInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                        </button>                       
                        </div> 
                    </div> 
                </div> 
            </div>

        {/* Modal for Segments register */}
        <div className="modal fade" id="segmentModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
                <div className="modal-dialog"> 
                    <div className="modal-content"> 
                        <div className="modal-header"> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                            <h4 className="modal-title" id="myModalLabel">Adicionar Segmento</h4> 
                        </div> 
                        <div className="modal-body-fundo">  
                        <form id="input"> 
                        <div className={segmentsInput.tipoSegmento === '0' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Tipo Segmento </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <select                                  
                                            className="form-control" 
                                            name="tipoSegmento"
                                            value={segmentsInput.tipoSegmento}
                                            onChange={handleNumberFields}> 
                                            <option value="" hidden></option> 
                                            <option value="1">Renda Fixa</option> 
                                            <option value="2">Renda Variável</option> 
                                            <option value="3">Estruturado</option> 
                                            <option value="4">Imobiliário</option> 
                                            <option value="5">Operações com Participantes</option> 
                                            <option value="6">Exterior</option> 
                                        </select>
                                    </div> 
                                </div>  
                            </div>
                            <div className={segmentsInput.limiteMinimo === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Limite Mínimo</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                    <div className="input-group">
                                        <input type="text"                                  
                                            className="form-control" 
                                            name="limiteMinimo"
                                            value={segmentsInput.limiteMinimo}
                                            onChange={handlePercFields}/> 
                                            <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                        </div>
                                    </div> 
                                </div>  
                            </div>
                            <div className={segmentsInput.previsaoDeAlocacao === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Previsão de Alocação</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                    <div className="input-group">
                                        <input type="text"                                  
                                            className="form-control" 
                                            name="previsaoDeAlocacao"
                                            value={segmentsInput.previsaoDeAlocacao}
                                            onChange={handlePercFields}/>
                                            <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                         </div> 
                                    </div> 
                                </div>  
                            </div>
                            <div className={segmentsInput.limiteMaximo === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Limite Máximo</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text"
                                                className="form-control" 
                                                name="limiteMaximo"
                                                value={segmentsInput.limiteMaximo}
                                                onChange={handlePercFields}/> 
                                                <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                        </div>
                                    </div> 
                                </div>  
                            </div>
                            <div className={segmentsInput.metaDeRentabilidadeSegmento === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Meta de Rentabilidade</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text"
                                                className="form-control" 
                                                name="metaDeRentabilidadeSegmento"
                                                value={segmentsInput.metaDeRentabilidadeSegmento}
                                                onChange={handlePercFields}/>
                                                <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span> 
                                        </div>
                                    </div> 
                                </div>  
                            </div>
                            <div className={segmentsInput.rentabilidadeAcumuladaSegmento === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Rentabilidade Acumulada</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text"
                                                className="form-control" 
                                                name="rentabilidadeAcumuladaSegmento"
                                                value={segmentsInput.rentabilidadeAcumuladaSegmento}
                                                onChange={handlePercFields}/> 
                                                <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span> 
                                        </div>
                                    </div> 
                                </div>  
                            </div>         
                        </form>      
                        </div> 
                        <div className="modal-footer">  
                        <button  
                            type="button"  
                            className="btn btn-primary"  
                            data-dismiss="modal"
                            onClick={() => handlePushSegments(tableIndex, segmentsInput)}>  
                            <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                        </button>                       
                        </div> 
                    </div> 
                </div>
            </div>

        {/* Modal for Tax Index register */}
        <div className="modal fade" id="indexModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                     <div className="modal-header"> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                            <h4 className="modal-title" id="myModalLabel">Taxa Indice</h4> 
                        </div> 
                        <div className="modal-body-fundo">  
                        <form id="input"> 
                            <div className={indexTaxInput.participacao === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Participação </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                        <input type="text"                                  
                                            className="form-control" 
                                            name="participacao"
                                            id="taxaindice"
                                            value={indexTaxInput.participacao}
                                            onChange={handlePercFields}/> 
                                            <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                        </div>
                                    </div> 
                                </div>  
                            </div>
                            <div className={indexTaxInput.tipoTaxaIndice === '0' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Tipo Taxa de Indicie </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <select                                
                                        className="form-control" 
                                        name="tipoTaxaIndice"
                                        id="taxaindice"
                                        value={indexTaxInput.tipoTaxaIndice}
                                        onChange={handleNumberFields}>
                                            <option value="" hidden></option> 
                                            <option value="1">Taxa Atuarial</option> 
                                            <option value="2">Índice de Referência</option>
                                        </select> 
                                    </div> 
                                </div>  
                            </div>   
                            <div className={indexTaxInput.indexador === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Indexador </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <select                                  
                                            className="form-control" 
                                            name="indexador"
                                            value={indexTaxInput.indexador}
                                            id="taxaindice"
                                            onChange={handleInputChange}>
                                            <option value="" hidden></option>
                                            {indexadores.sort().map((x) => <option value={x} key={x}>{x}</option>)}
                                        </select> 
                                    </div> 
                                </div>  
                            </div> 
                            <div className={indexTaxInput.percIndex === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Indice (Percentual) </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text"                                 
                                                className="form-control" 
                                                name="percIndex"
                                                id="taxaindice"
                                                value={indexTaxInput.percIndex}
                                                onChange={handlePercFields}/> 
                                                <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                        </div>
                                    </div> 
                                </div>  
                            </div> 
                        <div className={indexTaxInput.txOperacao === '' ? 'form-group  has-error' : 'form-group'}>
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                     <label className="control-label">Taxa Operação </label> 
                                </div> 
                                <div className="col-sm-8">
                                    <div className="input-group">
                                        <input type="text"
                                        className="form-control" 
                                        name="txOperacao"
                                        id="taxaindice"
                                        value={indexTaxInput.txOperacao}
                                        onChange={handlePercFields}/> 
                                        <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                    </div> 
                                </div> 
                            </div>  
                        </div>                           
                    </form>      
                    </div> 
                    <div className="modal-footer">  
                    <button  
                        type="button"  
                        className="btn btn-primary"  
                        data-dismiss="modal" 
                        data-toggle="modal"
                        data-target="#planBenView"  
                        onClick={() => handlePushIndex(tableIndex, indexTaxInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                    </button>                       
                    </div> 
                </div> 
            </div> 
        </div>

        {/* Modal for Benefit Plan Rental register */}
        <div className="modal fade" id="RentalModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                     <div className="modal-header"> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                            <h4 className="modal-title" id="myModalLabel">Rentabilidade Auferida</h4> 
                        </div> 
                        <div className="modal-body-fundo">  
                        <form id="input"> 
                        <div className={rentalInput.ano === '' ? 'form-group  has-error' : 'form-group'}>
                            <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Ano</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <input type="text"                                  
                                        className="form-control" 
                                        name="ano"
                                        maxLength="4"
                                        value={rentalInput.ano}
                                        onChange={handleNumberFields}/> 
                                    </div> 
                                </div>   
                            </div> 
                            <div className={rentalInput.valorRentabilidade === '' ? 'form-group  has-error' : 'form-group'}> 
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Valor Rentabilidade </label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text" 
                                            className="form-control" 
                                            name="valorRentabilidade"
                                            value={rentalInput.valorRentabilidade}
                                            onChange={handlePercFields}/> 
                                            <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                        </div>
                                    </div> 
                                </div>  
                            </div>                           
                        </form>      
                    </div> 
                    <div className="modal-footer">  
                    <button  
                        type="button"  
                        className="btn btn-primary"  
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#planBenView"    
                        onClick={() => handlePushPlanBenRental(tableIndex, rentalInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                    </button>                       
                    </div> 
                </div> 
            </div> 
        </div>

        {/* Modal for ALL Benefit Plans info */}
        <div className="modal fade bs-example-modal-lg" id="planBenView" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content-invest">  
                        <div className="panel panel-default"> 
                            <div className="panel-heading clearfix"> 
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h3 className="panel-title pull-left"> Taxa Indice </h3> 
                            </div> 
                            <div className="panel-body"> 
                                <table border="1" bordercolor="#b28316" className="table table-striped "> 
                                        <thead className="thead-light"> 
                                            <tr> 
                                                <th scope="col" className="text-center">Participação</th> 
                                                <th scope="col" className="text-center">Tipo Taxa Indicie</th> 
                                                <th scope="col" className="text-center">Indexador</th> 
                                                <th scope="col" className="text-center">Indice %</th> 
                                                <th scope="col" className="text-center">Taxa Operação</th> 
                                                <th scope="col" className="text-center">
                                                    <span className="input-group-btn"> 
                                                    <button className="btn btn-default" title="Adicionar Indice" type="button" 
                                                    data-dismiss="modal"
                                                    data-toggle="modal" data-target="#indexModal"> 
                                                    <i className="glyphicon glyphicon-plus"></i></button> 
                                                    </span>
                                                </th>                                     
                                            </tr> 
                                        </thead> 
                                    <tbody>          
                                    {objects[0] && objects[0].planodebeneficio.length > 0 && objects[0].planodebeneficio[tableIndex]['taxaIndice'].map(function(item, index){ 
                                    return( 
                                        <tr key={index}>  
                                            <td className="text-center">{item.participacao}</td> 
                                            <td className="text-center">{item.tipoTaxaIndice === '1' ? 'Taxa Atuarial' : 'Índice de Referência'}</td>
                                            <td className="text-center">{item.indexador}</td>
                                            <td className="text-center">{item.percIndex}</td>
                                            <td className="text-center">{item.txOperacao}</td>
                                            <td className="text-center">
                                                <span className="input-group-btn"> 
                                                <button className="btn btn-default" type="button" title="Remover Taxa de indice" onClick={() => handleRemoveTaxIndice(index)}> 
                                                <i className="fa fa-trash"></i></button> 
                                                </span>
                                            </td>
                                        </tr> 
                                    ) 
                                    })}        
                                    </tbody> 
                                </table>
                            </div>
                        </div>        
                        <div className="panel panel-default"> 
                            <div className="panel-heading clearfix"> 
                             <h3 className="panel-title pull-left"> Rentabilidade Auferida </h3> 
                            </div> 
                            <div className="panel-body">
                                <table border="1" bordercolor="#b28316" className="table table-striped"> 
                                        <thead className="thead-light"> 
                                            <tr> 
                                                <th scope="col" className="text-center">Ano</th> 
                                                <th scope="col" className="text-center">Valor Rentabilidade</th> 
                                                <th scope="col" className="text-center">
                                                    <span className="input-group-btn"> 
                                                    <button className="btn btn-default" title="Adicionar Rentabilidade" type="button" 
                                                    data-dismiss="modal"
                                                    data-toggle="modal" data-target="#RentalModal"> 
                                                    <i className="glyphicon glyphicon-plus"></i></button> 
                                                    </span>
                                                </th>
                                            </tr> 
                                        </thead> 
                                    <tbody>          
                                    {objects[0] && objects[0].planodebeneficio.length > 0 && objects[0].planodebeneficio[tableIndex]['rentabilidadeAuferida'].map(function(item, index){ 
                                    return( 
                                        <tr key={index}>  
                                            <td className="text-center">{item.ano}</td> 
                                            <td className="text-center">{item.valorRentabilidade}</td>  
                                            <td className="text-center">
                                                <span className="input-group-btn"> 
                                                <button className="btn btn-default" type="button" title="Remover Rentabilidade Auferida" onClick={() => handleRemovePlanBenRental(index)}> 
                                                <i className="fa fa-trash"></i></button> 
                                                </span>
                                            </td>
                                        </tr> 
                                    ) 
                                    })}        
                                    </tbody> 
                                </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        {/* Modal Table for Segments info */}                            
        <div className="modal fade bs-example-modal-lg" id="modalSegmentView" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel2" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content-invest">  
                    <div className="panel panel-default"> 
                        <div className="panel-heading clearfix">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                            <h3 className="panel-title pull-left"> Segmentos </h3> 
                        </div> 
                        <div className="panel-body"> 
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                            <thead className="thead-light"> 
                            <tr> 
                                <th scope="col" className="text-center">Tipo Segmento</th> 
                                <th scope="col" className="text-center">Limite Minimo</th> 
                                <th scope="col" className="text-center">Previsão de Alocação</th> 
                                <th scope="col" className="text-center">Limite Máximo</th> 
                                <th scope="col" className="text-center">Meta de Rentabilidade</th> 
                                <th scope="col" className="text-center">Rentabilidade Acumulada</th>  
                                <th scope="col" className="text-center">Adicionar Rentabilidade Auferida</th> 
                                <th scope="col" className="text-center">Visualizar Rentabilidades</th> 
                                <th scope="col" className="text-center">Remover Segmento</th> 
                            </tr> 
                            </thead> 
                                <tbody>          
                                {objects[0] && objects[0].planodebeneficio.length > 0 && objects[0].planodebeneficio[tableIndex].segmentos.map(function(item, index){ 
                                return( 
                                <tr key={index}>  
                                    <td className="text-center">{changeSegmentType(item.tipoSegmento)}</td> 
                                    <td className="text-center">{item.limiteMinimo}</td> 
                                    <td className="text-center">{item.previsaoDeAlocacao}</td> 
                                    <td className="text-center">{item.limiteMaximo}</td> 
                                    <td className="text-center">{item.metaDeRentabilidadeSegmento}</td> 
                                    <td className="text-center">{item.rentabilidadeAcumuladaSegmento}</td> 
                                    <td scope="col" className="text-center">
                                            <span className="input-group-btn"> 
                                            <button className="btn btn-default"
                                            title="Adicionar Rentabilidade" type="button" 
                                            data-dismiss="modal"
                                            data-toggle="modal"
                                            data-target="#rentalSegmentsModal"
                                            onClick={() => setSegmentIndex(index)}> 
                                            <i className="glyphicon glyphicon-plus"></i></button> 
                                        </span>
                                    </td> 
                                    <td scope="col" className="text-center">
                                            <span className="input-group-btn"> 
                                            <button className="btn btn-default"
                                            title="Visualizar Rentabilidade" type="button"
                                            data-dismiss="modal" 
                                            data-toggle="modal"
                                            data-target="#modalSegmentRentalView"
                                            onClick={() => setSegmentIndex(index)}> 
                                            <i className="fa fa-eye"></i></button> 
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="input-group-btn"> 
                                        <button className="btn btn-default" type="button" title="Remover Segmento" onClick={() => handleRemoveSeg(index)}> 
                                        <i className="fa fa-trash"></i></button> 
                                        </span>
                                    </td>
                                </tr> 
                                ) 
                                })}        
                            </tbody> 
                        </table>
                    </div>
                </div>        
            </div>
        </div>
    </div>
    
        {/* Modal for Rental Segments register*/}
        <div className="modal fade" id="rentalSegmentsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Rentabilidade Auferida (Segmento)</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                        <form id="input"> 
                        <div className={rentalSegmentsInput.anoSegmento === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Ano</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                     <input type="text"                                  
                                        className="form-control" 
                                        name="anoSegmento"
                                        maxLength="4"
                                        value={rentalSegmentsInput.anoSegmento}
                                        onChange={handleNumberFields}/> 
                                    </div> 
                                </div>  
                            </div>
                            <div className={rentalSegmentsInput.valorRentabilidadeSegmento === '' ? 'form-group  has-error' : 'form-group'}>
                                <div className="row">  
                                    <div className="col-sm-4 text-right"> 
                                        <label className="control-label">Valor Rentabilidade</label> 
                                    </div> 
                                    <div className="col-sm-8"> 
                                        <div className="input-group">
                                            <input type="text"
                                                className="form-control" 
                                                name="valorRentabilidadeSegmento"
                                                value={rentalSegmentsInput.valorRentabilidadeSegmento}
                                                onChange={handlePercFields}/> 
                                                <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span> 
                                        </div>
                                    </div> 
                                </div>  
                            </div>           
                        </form>      
                        </div> 
                        <div className="modal-footer">  
                        <button  
                            type="button"  
                            className="btn btn-primary"  
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#modalSegmentRentalView"
                            onClick={() => handlePushRentalSegments(tableIndex, rentalSegmentsInput)}>  
                            <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                        </button>                       
                    </div> 
                </div> 
            </div>
        </div>
    
        {/* Modal for Rental Segments info */ }
        <div className="modal fade" id="modalSegmentRentalView" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content-invest">  
                    <div className="panel panel-default"> 
                        <div className="panel-heading clearfix"> 
                            <h3 className="panel-title pull-left"> Rentabilidade Auferida </h3> 
                        </div> 
                        <div className="panel-body"> 
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                            <thead className="thead-light"> 
                            <tr> 
                                <th scope="col" className="text-center">Ano</th> 
                                <th scope="col" className="text-center">Valor Rentabilidade</th> 
                                <th scope="col" className="text-center">Remover Rentabilidade</th> 
                            </tr> 
                            </thead> 
                                <tbody>          
                                {objects[0] && objects[0].planodebeneficio.length > 0 
                                && objects[0].planodebeneficio[tableIndex].segmentos.length > 0 && objects[0].planodebeneficio[tableIndex].segmentos[segmentIndex].rentabilidadeAuferidaSegmento.map(function(item, index){ 
                                return( 
                                <tr key={index}>  
                                    <td className="text-center">{item.anoSegmento}</td> 
                                    <td className="text-center">{item.valorRentabilidadeSegmento}</td>  
                                    <td className="text-center">
                                        <span className="input-group-btn"> 
                                        <button className="btn btn-default" type="button" title="Remover Rentabilidade"
                                        onClick={() => handleRemoveRentalSeg(index)}> 
                                        <i className="fa fa-trash"></i></button> 
                                        </span>
                                    </td>
                                </tr> 
                                ) 
                                })}        
                            </tbody> 
                        </table>
                    </div>
                    <div className="panel-footer">
                    <button  
                            type="button"  
                            className="btn btn-secondary" 
                            onClick={() => setSegmentIndex(0)} 
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#modalSegmentView"
                            >  
                            Voltar 
                    </button>
                    </div>
                </div>        
            </div>
        </div>
    </div>
    </div>          
    )
}
  

export default InvestmentPolicy;
