import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const InputsDate = ({
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  getReports,
}) => {
  return (
    <>
      <div className="header-report">
        <h3>Relatório de downloads </h3>
      </div>
      <div className="box-input-date">
        <label htmlFor="initialDate" className="label-date">
          Data inicial
        </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          id="initialDate"
          className="input-date text-center"
          locale={ptBR}
          selected={initialDate}
          onChange={(value) => setInitialDate(value)}
        />
        <label htmlFor="finalDate" className="label-date">
          Data final
        </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          id="finalDate"
          className="input-date text-center"
          locale={ptBR}
          selected={finalDate}
          onChange={(value) => setFinalDate(value)}
        />
        <button
          className="btn btn-primary button-getReports"
          onClick={() => getReports()}
        >
          Obter relatório
        </button>
      </div>
    </>
  );
};

export default InputsDate;
