import React from 'react';
import {useSelector} from 'react-redux';

import NavBar from '../NavBar';



function Header(){
    const validToken =  useSelector(state => state.auth.validToken);
    return (
        <div>
           {validToken ? <NavBar/> : null}
        </div>
    )
}

export default Header;