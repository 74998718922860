import React from 'react';
import { Router } from 'react-router-dom';

import { history } from './helpers/history';
import Header from './components/Header'
import Routers from './routes';

function App() {

  return (
    <div>
      <Router history={history}> 
        <Header/>
        <Routers/>
      </Router>
    </div>
  );
}

export default App;
