import React, {useState, useEffect} from 'react';

import api from '../../services/api';


function SheilaDirectory(){
    const [s3Files, setS3Files] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const files = await api.get(`getWtwS3Files`) ;

            setS3Files(files.data.contents)       
            return files
          } catch (e) {
            console.log(e);
          }
        };
        fetchData()
    }, []);

    const downloadFile = (fileName, fileKey) => {
        api.post('/downloadWtwFile', {fileName, fileKey})
        .then(resp => { 
            const link = document.createElement('a');
            link.href = resp.data.content;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(e => {
          alert('Você não tem permissão para download ou o sistema de download está fora do ar.');
        });
    }

    const renderFilesToDownload = () => {
        return s3Files && s3Files.map((obj, index) => {
            var fileName = obj.key.replace(/^.*[\\\/]/, '')

            return (
            fileName != '' ? 
                <tr key={index}>
                    <td>
                        {fileName}
                    </td> 
                    <td>
                        <button className='btn btn-primary' onClick={() => downloadFile(fileName, obj.key)}>
                            <i className="fa fa-download align-left"> Download</i>
                        </button>
                    </td>              
                </tr> 
            : null          
            )
        })
    }

    return (
        <div className="container">
            <div className="filesToDownload">
            <table className="table table-striped table-bordered mydatatable">
                <thead className='text-center'>
                    <tr>
                        <th>Nome do Arquivo</th>
                        <th>Link para Download</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {renderFilesToDownload()}
                </tbody>
            </table>
        </div>
        </div>
    )
}

export default SheilaDirectory;