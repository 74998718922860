import React, { useState, useEffect } from 'react';

import api from '../../services/api';


function InvestimentClub(){
    const [movies, setMovies] = useState([]);

    useEffect(() =>{
        api.get('/clubmovies')
        .then(resp => { 
            setMovies(resp.data.movies)
          
        })
        .catch(e => {
          console.log(e);
        });
    },[])

    return(
        <div>
            {movies && movies.map((movieName, index) => {
                return  <div>
                            <h5>{movieName.split(".")[0]}</h5>
                            <video key={index} width="700" height="400" controls>
                                <source src={`https://investimentclub.s3-sa-east-1.amazonaws.com/movies/${movieName}`} type="video/mp4"></source>
                            </video>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
            })}
        </div>  
    )
}

export default InvestimentClub;