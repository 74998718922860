import React,{ useState } from 'react';
import {useSelector} from 'react-redux';

import { Link } from 'react-router-dom';

import './styles.css';

import Reports from '../../components/Reports';
import Benchmarks from '../../components/Benchmarks';
import FundsRegister from '../../components/FundsRegister';
import OpParticipants from '../../components/OpParticipants'
import InvestmentPolicy from '../../components/InvestmentPolicy'
import BoxSection from '../../components/BoxSection'
import SheilaDirectory from '../../components/SheilaDirectory'

import InvestmentClub from '../../components/InvestimentClub'



function Main(){

    const userLogin = useSelector(state => state.auth.credentials.user_login);
    const specialLogin = ['tag']
    const allowedLoginWtw = ['towersdados', 'netquant']

    const [componentName, setComponentName] = useState(specialLogin.indexOf(userLogin) >= 0 ? 'FundsRegister' : 'Reports') 

    const changeComponent = () => {
        switch(componentName){
            case 'Reports':
                return <Reports/>
            case 'SheilaDirectory':
                return <SheilaDirectory/>
            case 'Benchmarks':
                return <Benchmarks/>
            case 'FundsRegister':
                return <FundsRegister/>
            case 'OperPart':
                return <OpParticipants/>
            case 'InvestmentPolicy':
                return <InvestmentPolicy/>
            case 'BoxSection':
                return <BoxSection/>
            case 'InvestimentClub':
                return <InvestmentClub/>    
            default:
                return null;
        }
    }


    return(
        <div>
        <div id="throbber" ></div>
        <div id="noty-holder"></div>
        <div id="wrapper2">
   
    <nav role="navigation">
    
        <div className="collapse navbar-collapse navbar-ex1-collapse">
            <ul className="nav navbar-nav side-nav">
                 <li>
                    <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-1"><i className="fa fa-fw fa-search"></i> MENU<i className="fa fa-fw fa-angle-down pull-right"></i></a>
                    <ul id="submenu-1" className="collapse">
                        <li><Link to="/market" className="fa fa-angle-double-right"> RESUMO DE MERCADO</Link></li>
                        <li><Link to="/folioview" className="fa fa-angle-double-right"> FOLIOVIEW</Link></li>
                    </ul>
                </li>

                <li>
                    <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-2"><i className="fa fa-cogs"></i> FERRAMENTAS<i className="fa fa-fw fa-angle-down pull-right"></i></a>
                    <ul id="submenu-2" className="collapse"> 
                        <li className="text-left">
                            <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-2-1"><i className="fa fa-angle-double-right"></i> PREVIC<i className="fa fa-fw fa-angle-down pull-right"></i></a>
                            <ul id="submenu-2-1" className="collapse">  
                                <li>
                                    <a id="FundsRegister" href="#previc-cadfd" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-suitcase"></i>Cadastro de Fundos
                                    </a>
                                </li>
                                <li>
                                    <a id="OperPart" href="#previc-prct-opparts" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-group"></i>Op. Participantes
                                    </a>
                                </li>
                                <li>
                                    <a id="InvestmentPolicy" href="#previc-prct-investmentpolicy" onClick={ e => setComponentName(e.target.id)}  ><i className="fa fa-bar-chart"></i>Polít. de Investimento
                                    </a>
                                </li>
                                { userLogin == 'netquant' ?
                                <li>
                                    <a id="BoxSection" href="#previc-secao-caixa" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-inbox"></i>Seção Caixa
                                    </a>
                                </li> : null }
                            </ul>
                        </li>
                    </ul>
                </li>     
                { specialLogin.indexOf(userLogin) < 0 ? (
                <li>
                    <a id="Reports" href="#reports" onClick={ e => setComponentName(e.target.id)}><i className="fa  fa-th-list"></i>  RELATÓRIOS</a>
                </li> ) 
                : null }
                { allowedLoginWtw.indexOf(userLogin) >= 0 ? (
                <li>
                    <a id="SheilaDirectory" href="#nqfiles" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-download"></i>  ARQUIVOS NQ</a>
                </li> ) 
                : null }
                <li>
                    <a id="Benchmarks" href="#benchmarks" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-line-chart"></i>  BENCHMARKS</a>
                </li>
                { specialLogin.indexOf(userLogin) < 0 ? (
                <li>
                    <a id="InvestimentClub" href="#club" onClick={ e => setComponentName(e.target.id)}><i className="fa fa-play-circle"></i> CLUBE DE INVESTIMENTOS</a> 
                </li> ) 
                : null }
            </ul>
        </div>
    </nav>

    <div id="page-wrapper2">

        <div className="container-fluid">
            <div className="row" id="main" >
                <div className="col-sm-12 col-md-12 well" id="content">
                
                <div className="text-center">
                {componentName == "FundsRegister" ?
                    <h4> Cadastro de Fundos </h4>
                : null } 
                {componentName == "OperPart" ?
                    <h4> Operação com Participantes </h4>
                : null } 
                {componentName == "BoxSection" ?
                    <h4> Seção Caixa </h4> : null }
                {componentName == "InvestmentPolicy" ?
                    <>
                        <div className="alert alert-warning" role="alert" style={{marginTop:'0px', zIndex:'9999'}}>
                            Ferramenta funcionando somente pelo preenchimento da planilha.
                            Envio pelo botão "Enviar template preenchido".
                        </div>
                        <h4> Política de Investimento </h4>
                    </> : null }
                {componentName == "SheilaDirectory" ?
                    <h4> Seção de download de arquivos </h4> : null }
                </div>
                {changeComponent()}
                {componentName == "FundsRegister" || componentName == "OperPart" || componentName == "BoxSection" || componentName == "InvestmentPolicy"?
                <div className="bs-callout bs-callout-warning" style={{marginTop: '30px'}}>
                <div className="alert alert-warning" style={{textAlign:'center'}}>      
                    <h4 className="mt-4"> Limitação de Responsabilidades:</h4>
                    <p>
                        A NetQuant e a Willis Towers Watson não se responsabilizam pelo conteúdo dos arquivos XMLs gerados. A informação reflete somente a informação fornecida pelo usuário através da planilha XLSX ou/e do formulário preenchido e enviado pelo mesmo.
                    </p>
                </div>
                </div>
                : null}
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    )
}

export default Main;