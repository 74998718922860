import React,{ useState} from 'react';
import { Link } from 'react-router-dom';

import api from '../../../services/api';
import { resetForm } from '../../../common'

import './styles.css'

function Create(){

    const [formInput, setFormInput] = useState([{name: '', login: '', password:'', password_confirmation: '', msg: ''}])


    const handleUserCreate = e => {
        const {name, login, password} = formInput;
        api.post(`/users`, {name, login, password})
            .then(resp => { 
                setFormInput({...formInput, msg: resp.data.success})
                resetForm('createUserForm');
        })
        .catch(e => {
            console.log(e.response.data.error)
            setFormInput({...formInput, msg: e.response.data.error})
        });
    };

    const handleFormInput = e => {
        e.preventDefault();

        if(formInput.password !== formInput.password_confirmation){
            setFormInput({...formInput, msg: 'A senha e a confirmação não batem!'});
        }else if (formInput.password.length < 6){
            setFormInput({...formInput, msg: 'A senha precisar ter, no mínimo, 6 digitos!'});
        }else{
            handleUserCreate();
        }

    };

    return(
          <div className="login-form">
            <form id="createUserForm" onSubmit={handleFormInput}> 
            <center><h3><i class="fa fa-user-plus fa-4x"></i></h3></center>
            <center> <h2 class="text-center">Criação de Usuário</h2></center>
            <center><p>Criando um novo usuário.</p></center>
              <div className="form-group">  
                <input
                  type="text"
                  placeholder="Nome"
                  className="form-control"
                  autoComplete="off"
                  required
                  onChange={e => setFormInput({...formInput, name: e.target.value })}
                />
              </div>
              {/*<div className="form-group">  
                <input
                  type="Email"
                  placeholder="Email"
                  className="form-control"
                  autoComplete="off"
                  required
                  onChange={e => setFormInput({...formInput, email: e.target.value })}
                />
              </div>
              */}
              <div className="form-group">  
                <input
                  type="text"
                  placeholder="Login"
                  className="form-control"
                  autoComplete="off"
                  required
                  onChange={e => setFormInput({...formInput, login: e.target.value })}
                />
              </div> 
              <div className="form-group">   
                <input
                  type="password"
                  placeholder="Senha"
                  autoComplete="off"
                  className="form-control"
                  required
                  onChange={e => setFormInput({ ...formInput, password: e.target.value })}
                />
              </div>
              <div className="form-group">   
                <input
                  type="password"
                  placeholder="Confirmação da Senha"
                  className="form-control"
                  autoComplete="off"
                  required
                  onChange={e => setFormInput({ ...formInput, password_confirmation: e.target.value })}
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">Criar</button>
              </div>
              <hr />
              <center>{formInput.msg}</center>
              <center><Link to="/admin/users"> Cancelar </Link></center>
            </form>
        </div>
    )
}

export default Create;