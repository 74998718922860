import React, {useState, useEffect} from 'react';

import api from '../../services/api';


function Benchmarks(){

    const [benchmarks, setBenchmarks] = useState(null);

    useEffect( () => {
        api.get('benchmarks')
        .then(resp => { 
            let parser = new DOMParser();
            let xml = parser.parseFromString(resp.data,"text/xml");

            setBenchmarks(xml);
        })
        .catch(e => {
          console.log(e);
        }); 


    }, [])



    function printIndent(element) {
        let level = parseInt(element.getAttribute("level"));
        let spaces = [];
        for (let i = 0; i < level; i++) {
            spaces.push(<span>&nbsp;&nbsp;</span>);
        }
        return spaces;
    }

    const getElementsBody = (period) => {
        let elements = benchmarks.getElementsByTagName("Benchmark");
        let elements_period = [];
        let elements_tr = [];
        let elements_to_remove = ['IMA-C-TOTAL']

        for(let element of elements){
            if(element.getAttribute('period') == period) {
               elements_period.push(element);
            }
        }

        elements_tr = elements_period.map((element, index) =>
            !elements_to_remove.includes(element.getElementsByTagName("Name")[0].innerHTML) ?
                <tr >
                    <td>&nbsp;</td>
                            <td height="15">{printIndent(element)}{element.getElementsByTagName("Name")[0].innerHTML}</td>
                            <td align="right">{element.getElementsByTagName("Risk")[0].innerHTML}</td>
                            <td align="right">{element.getElementsByTagName("Yield")[0].innerHTML}</td>
                            <td align="right">{element.getElementsByTagName("Date")[0].innerHTML}</td>
                    <td>&nbsp;</td>			   
                </tr>
            : null
        )
        return elements_tr;

    };

    const getElementsHeader = () =>{
        return(
            <tr bgcolor="#CACACA">
                <td width="20">&nbsp;</td>
                <td height="20"><strong>Benchmark</strong></td>
                <td width="79" height="20" align="center"><strong>Risco</strong></td>
                <td width="80" height="20" align="center"><strong>Retorno</strong></td>
                <td width="84" height="20" align="center"><strong>Data</strong></td>
                <td width="20" height="20">&nbsp;</td>
            </tr>      
        )
    }

    const mountTable = (period) => {
        let str_period = '';
        switch (period) {
            case "1": 
                str_period = "Mensal";
                break;
            case "ytd": 
                str_period = "No Ano";
                break; 
            case "12": 
                str_period = "12 Meses";
                break;
            case "24":
                str_period = "24 Meses";
                break;
            }
        
        return(
            <div>
                <div><div colSpan="6"><b>{str_period}</b></div></div>
                <table border="0" cellSpacing="0" cellPadding="0" className="texto" className="table table-striped">
                    <thead>
                        {getElementsHeader()}
                    </thead>
                    <tbody>
                        {getElementsBody(period)}
                    </tbody>
                </table><br></br>
            </div>
               
        )

    }

    return (
        <div className="container">
            {benchmarks && 
                <div className="row">
                            <div className="col-xs-5">
                                {mountTable('1')}
                            </div>
                            <div className="col-xs-5">
                                {mountTable('ytd')} 
                            </div>
                            <div className="col-xs-5">
                                {mountTable('12')}
                            </div>
                            <div className="col-xs-5">
                                {mountTable('24')} 
                            </div>
                </div>

            }
        </div>
    
          
    )
}

export default Benchmarks;