import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import  ScrollToTop  from  "react-scroll-up";

import { loginAction } from '../../actions/authAction';

import up_arrow from '../../assets/images/up_arrow_round.png';
import logo_nq from '../../assets/images/logo_nq.png';




function Signin(){
    const [loginInput, setLoginInput] = useState([{login: '', password: '', error: ''}]);
    const error = useSelector(state => state.auth.error);
    const dispatch = useDispatch();

    const handleSignIn = e => {
        e.preventDefault();
        dispatch(loginAction(loginInput));
        removeModalBackDrop();
        
    };

    const removeModalBackDrop = () =>{
        document.getElementsByTagName("body")[0].removeAttribute("style");
        document.getElementsByTagName("body")[0].removeAttribute("class");
        let el = document.getElementsByClassName("modal-backdrop");
        console.log(el[0]);
            if (el[0]){
                el[0].removeAttribute("class");
            }
   
    }
    
    return (
        <div className="site_wrapper">
            
            <div className="topbar light">
                <div className="container">
                    <div className="topbar-left-items">
                        <div className="margin-top1"></div>
                        <ul className="toplist toppadding pull-left paddtop1">
                        <li className="rightl"></li>
                        </ul>
                    </div>
                    <div className="topbar-right-items pull-right">
                        <ul className="toplist toppadding">
                        <li> <span data-toggle="modal" data-target="#responsive-modal" className="model_img img-responsive"> <a href="#"> LOGIN <i className="fa fa-angle-down"></i></a> </span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div id="responsive-modal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">× </button> 
                        <h4 className="modal-title"></h4>
                    </div>
                    <div className="modal-body">
                    <section id="wrapper" className="login-register login-sidebar">
                        <div className="login-box card">
                        <div className="card-body">
                            <form className="form-horizontal form-material" id="loginform" onSubmit={handleSignIn}>
                                <a href="javascript:void(0)" className="text-center db"><img src={logo_nq} alt="Home" /><br/></a>  
                                
                                <div className="form-group m-t-40">
                                    <div className="col-xs-12">
                                        <input 
                                            className="form-control"
                                            type="text"
                                            required="" 
                                            placeholder="Login"
                                            onChange={e => setLoginInput({...loginInput, login: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-12">
                                        <input 
                                            className="form-control" 
                                            type="password" 
                                            required="" 
                                            placeholder="Senha"
                                            onChange={e => setLoginInput({ ...loginInput, password: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <center>{error}</center>
                                <div className="form-group">
                                    <div className="col-xs-12"> 
                                       {/*<Link to="/forgot_password" id="to-recover" className="text-dark pull-right" onClick={() => removeModalBackDrop()} ><i className="fa fa-lock m-r-5"></i> &nbsp; Esqueceu sua senha? </Link> */}
                                    </div>
                                </div>
                                
                                <div className="form-group text-center m-t-20">
                                    <div className="col-xs-12">
                                    <button className="btn btn-orange2 btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Log In</button>
                                    </div>
                                </div>
                                <div className="form-group m-b-0">
                                    <div className="col-sm-12 text-center">
                                    </div>
                                </div>
                            </form>
                        </div>
                        </div>
                    </section>
                    </div>
                </div>
            </div>
            </div>
           

            <div className="clearfix"></div>
            
            
            <div id="header">
                <div className="container">
                <div className="navbar navbar-default yamm">
                    <div className="navbar-header">
                    <button type="button" data-toggle="collapse" data-target="#navbar-collapse-grid" className="navbar-toggle two three"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                    <a href="javascript:void(0)" className="navbar-brand"><img src={logo_nq} alt=""/></a> </div><br></br>
                    <div id="navbar-collapse-grid" className="navbar-collapse collapse pull-right">
                    <ul className="nav navbar-nav">
                        <li className="dropdown"> <a href="javascript:void(0)" className="dropdown-toggle active">Home</a> </li>
                        <li className="dropdown yamm-fw"> <a href="#quemsomos" className="dropdown-toggle">Quem Somos</a> </li>
                        <li className="dropdown yamm-fw"><a href="#parcerias" className="dropdown-toggle">Parcerias</a> </li>
                        <li> <Link to="/market" >RESUMO DE MERCADO</Link> </li>
                        <li className="dropdown"> <a href="#contato" className="dropdown-toggle">Contato</a> </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            

            <div className="clearfix"></div>
            
            <section className="parallax-section11">
                <div className="container sec-tpadding-3 sec-bpadding-2">
                <div className="row">
                    <div className="col-md-4">
                        <div className="feature-box12">
                            <div className="iconbox-small round gray center"><i className="fa fa-bar-chart-o"></i></div>
                            <h5 className="text-white"> Folioview Pension</h5>
                            <p  className="text-white">Especialmente desenhado para fundos de pensão, o Folioview Pension oferece informações vitais...</p>
                            <br/>
                            <Link to="/folioview" className="read-more white"><i className="fa fa-angle-double-right"></i> Saiba Mais</Link>
                        </div>
                    </div>

                    
                    <div className="col-md-4">
                    <div className="feature-box12 ">
                        <div className="iconbox-small round gray center"><i className="fa fa-arrow-circle-o-up"></i></div>
                            <h5 className="text-white"> Folioview Insurance</h5>
                            <p  className="text-white">Com o Folioview Insurance as seguradoras podem dimensionar com precisão o risco de suas...</p>
                            <br/>
                            <Link to="/folioview" className="read-more white"><i className="fa fa-angle-double-right"></i> Saiba Mais</Link> 
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                    <div className="feature-box12">
                        <div className="iconbox-small round gray center"><span className="icon-piechart"></span></div>
                            <h5 className="text-white"> Pension Club</h5>
                            <p  className="text-white">O Pension Club foi criado em parceria com a Willis Towers Watson, com o objetivo de fornecer... </p>
                            <br/>
                            <Link to="/folioview" className="read-more white"><i className="fa fa-angle-double-right"></i> Saiba Mais</Link>
                        </div>
                    </div>
                    
                </div>
                </div>
            </section>
            <div className="clearfix"></div>
            
            
            <div className="clearfix"></div>
            
            <section className=" section-light sec-padding">
                <div className="container" id="quemsomos">
                <div className="row">
                    <div className="col-xs-12 text-center">
                    <h2 className="section-title-2 uppercase">Quem Somos?</h2>
                    <div className="title-line-8"></div>
                    <br></br>
                    </div>
                    <div className="clearfix"></div>
                    <p>A <b>NetQuant</b> foi fundada em 2000 com o objetivo de oferecer a gestores financeiros ferramentas analíticas para auxiliá-los em suas decisões estratégicas de investimento. A empresa é especializada no mercado brasileiro e desenvolve suas soluções considerando as particularidades locais. Em sua carteira de produtos a <b>NetQuant</b> possui soluções voltadas para entidades previdenciárias, fundações, seguradoras, empresas e asset managers.</p>
                        <br/>
                        <p>As ferramentas de gerenciamento e controle de carteiras de investimento da <b>NetQuant</b> são desenvolvidas com as mais modernas técnicas e metodologias em finanças, oferecendo aos clientes uma exata compreensão, dimensão e controle do risco de suas aplicações.</p>
                        <br/>
                        <br/>
                </div>
                </div>
            </section>
            
            <div className="clearfix"></div>
            
            <section className="parallax-section13">
                <div className="section-overlay dark2" id="parcerias">
                <div className="container sec-tpadding-2 sec-bpadding-2">
                    <div className="row">
                    <div className="col-md-8 col-centered">
                        <div>
                        <div className="item">
                            <h3 className="text-white less-mar1"> Parcerias</h3>
                            <br/>
                            <h5 className="text-white less-mar1"><a target="_blank" href="http://www.willistowerswatson.com">Willis Towers Watson</a></h5>
                            <h5 className="text-white">Em 2002, a NetQuant firmou uma parceria com a Willis Towers Watson, maior empresa de consultoria em 
                            recursos humanos do mundo. Especialista em consultoria atuarial e administração de planos de previdência, 
                            a Willis Towers Watson se aliou a NetQuant para fornecer produtos de análise de risco e performance para investidores 
                            institucionais.</h5>
                            <h5 className="text-white">Essa história de sucesso criou um serviço de consultoria financeira de excelência com ótima reputação no mercado.
                                Além do atendimento a mais de 50 entidades de previdência complementar, a parceria tem presença constante em publicações 
                                especializadas, congressos e seminários. Dentre os usuários do serviço estão fundações de importantes multinacionais como
                                Siemens, Saint Gobain, Unilever e Gebsaprev.</h5>
                            <h5 className="text-white less-mar1"><a target="_blank" href="http://www.abrapp.org.br">ABRAPP</a></h5>
                            <h5 className="text-white">Produzido em parceria com a ABRAPP, a Associação Brasileira de Entidades Fechadas de Previdência Complementar,
                                IGI – Indicadores de Gestão de Investimento – conta hoje com 173 entidades cadastradas. Este é o serviço de maior penetração no 
                                mercado de investidores institucionais brasileiro. Sob análise, estão cerca de 1200 fundos de investimento, segmentados por 
                                renda fixa conservadora e agressiva, multimercados e renda variável. O patrimônio total analisado é superior a R$ 440 bilhões.
                                Para ver o folder do produto clique aqui. Para se cadastrar visite o site http://sistemas.abrapp.org.br/igi/.</h5>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="clearfix"></div>
            
            <section className="sec-padding">
                <div className="container" id="contato">
                    <div className="row">
                    <div className="col-md-4 pull-right">
                        <h4>Onde estamos?</h4>
                        Praça Mahatma Gandhi, 2 - Sala 803/804 
                        Cinelandia, Rio de Janeiro - Brasil
                        CEP: 20031-100<br/>
                        Telefone:	+55 21 2286-3496  
                    </div>
                    </div>  
                </div>
            </section>

            <div className="clearfix"></div>
            
            <section className="section-copyrights sec-moreless-padding">
                <div className="container">
                <div className="row">
                    <div className="col-md-12"> <span>Copyright © {new Date().getFullYear()}  www.netquant.com.br All rights reserved.</span></div>
                </div>
                </div>
            </section>
            <div className="clearfix"></div>
          
            <ScrollToTop showUnder={160}>
                <img src={up_arrow} alt="up_arrow" />
            </ScrollToTop>
  
        </div>

    )
}

export default Signin;