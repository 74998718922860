import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import isAuthenticated  from './auth/isAuthenticated';
import { getCredentials }from './auth/credentials';


import SignIn from './pages/SignIn';

import Main from './pages/Main';

//import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';

import Market from './pages/Market';
import Folioview from './pages/Folioview'

import Users from './pages/Admin/Users';
import UserNew from './pages/Admin/Users/create';
import UserUpdate from './pages/Admin/Users/update';

import ReportPage from './pages/Reports/'




const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route {...rest} render= {props => ( 
            isAuthenticated() ? 
            (<Component { ...props} />) : 
            (<Redirect to={{pathname: '/signin', state: { from: props.location}}} />)
        )}
    />
);

const EspecialRoute = ({ component: Component, ...rest}) => (
    <Route {...rest} render= {props => ( 
            !isAuthenticated() ? 
            (<Component { ...props} />) : 
            (<Redirect to={{pathname: '/', state: { from: props.location}}} />)
        )}
    />
);

const AdminRoute = ({ component: Component, ...rest}) => (
    <Route {...rest} render= {props => ( 
            isAuthenticated() && getCredentials().user_admin ? 
            (<Component { ...props} />) : 
            (<Redirect to={{pathname: '/', state: { from: props.location}}} />)
        )}
    />
);

const Routes = () => (
        <Switch>
            <PrivateRoute exact path="/" component={Main} />
            <EspecialRoute path="/signin" component={SignIn} />
            {/*<Route path="/forgot_password" component={ResetPassword} />*/}
            <Route path="/market" component={Market} />
            <Route path="/folioview" component={Folioview} />
            <AdminRoute exact path="/admin/users" component={Users} />
            <AdminRoute path="/admin/users/new" component={UserNew} />
            <AdminRoute path="/admin/users/edit/:id" component={UserUpdate} />
            <AdminRoute path="/admin/users/password/:id" component={ChangePassword} />
            <AdminRoute exact path="/admin/reports" component={ReportPage} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
);

export default Routes;