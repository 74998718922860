import React from "react";

const EmptyReport = () => {
  return (
    <div className="empty-report">
      <h5>Não existe nenhum relatório para este período</h5>
    </div>
  );
};

export default EmptyReport;
