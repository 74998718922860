import React, { useState } from "react";
import { Link } from 'react-router-dom';

import api from '../../services/api';
import { resetForm } from '../../common';
import  "./styles.css";


function ChangePassword(props){
    const id = props.match.params.id;
    const [passwordsInput, setPasswordsInput] = useState([{password: '',
     password_confirmation: '', msg: ''}]);

    const ChangePassword = () => {
        const { password } = passwordsInput;
        api.put(`/users/${id}`,{ password })
        .then(resp => { 
          setPasswordsInput({...passwordsInput, msg: resp.data.success});
          resetForm('changePwForm');
        })
        .catch(e => {
          setPasswordsInput({...passwordsInput, msg: e.response.data.error});
        });
    }

    const handleChangePassword = e => {
        e.preventDefault();
        if(passwordsInput.password !== passwordsInput.password_confirmation){
            setPasswordsInput({...passwordsInput, msg: 'Nova senha e confirmação não batem!'});
        }else if (passwordsInput.password.length < 6){
            setPasswordsInput({...passwordsInput, msg: 'Sua senha precisar ter, no mínimo, 6 digitos!'});
        }else{
          ChangePassword();
        }
      };


    return(
        <div className="login-form">
        <form id="changePwForm" onSubmit={handleChangePassword}>
          <div class="text-center">
            <h3><i className="fa fa-unlock-alt fa-4x"></i></h3>
            <h2 className="text-center">Nova Senha</h2>
            <p>Crie uma nova senha.</p>
          </div>
          <div className="form-group">  
            <input
              type="password"
              placeholder="Nova Senha"
              className="form-control"
              required
              autoComplete="off"
              onChange={e => setPasswordsInput({...passwordsInput, password: e.target.value })}
            />
          </div>
          <div className="form-group">   
            <input
              type="password"
              placeholder="Confirmação da nova senha"
              className="form-control"
              required
              autoComplete="off"
              onChange={e => setPasswordsInput({ ...passwordsInput, password_confirmation: e.target.value })}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" type="submit">Alterar</button>
          </div>
          <hr />
          <center>{passwordsInput.msg}</center>
          <center><Link to="/admin/users"> Cancelar </Link></center>
        </form>
      </div>
        
    )
}


export default ChangePassword;
