import React, { useState, useReducer, useEffect } from 'react'; 

import XLSX from 'xlsx'; 

import api from '../../services/api';

function BoxSection(){
    const [headerInput, setHeaderInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        {     
          cnpjCpf: '', 
          codCart: '', 
          dtPosicao: '',
          nomeCart: '', 
          tipoPessoa: '',
          codCntCor: '',
          nomeGestor: '',
          cnpjGestor: '',
          nomeCustodiante: '',
          cnpjCustodiante: '',
          patLiq: '',
          valorTributos: '',
          valorAtivos: '',
          valorReceber: '',
          valorPagar: ''
        } 
      );

      const [caixaInput, setCaixaInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        {     
          isinInstituicao: '', 
          tipoConta: '', 
          saldo: '',
          nivelrsc: '' 
        } 
      );

      const [planPrevInput, setPlanPrevInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
        {     
          cnpb: '', 
          percPart: ''
        } 
      );

    const headerList = [
        'cnpjCpf', 'codCart', 'dtPosicao', 'nomeCart', 'tipoPessoa', 'codCntCor', 'nomeGestor','cnpjGestor', 'nomeCustodiante', 'cnpjCustodiante', 'patLiq', 'valorTributos', 'valorAtivos', 'valorReceber', 'valorPagar'
    ]

    const caixaList = [
        'isinInstituicao', 'tipoConta', 'saldo', 'nivelrsc'
    ]
    
    const planPrevList = [
        'cnpb', 'percPart'
    ]
    var titleString = "O preenchimento dos campos desta seção apenas se fará de forma obrigatória somente para arquivos de carteiras cujo titular é uma entidade fechada de previdência complementar, não devendo constar em arquivos de posição de fundos de investimento"

    const [objects, setObjects] = useState([]);  
    const handlePushHeader = (header) => {   
        setObjects([...objects,  {['header']: [header], ['depositos']: [], ['planPrev']: []}]) 
    }

    const handlePushCaixa = (caixa) => {
        objects.splice(1, 1)

        setObjects([...objects,  objects[0].depositos.push(caixa)]) 
        cleanCaixa();
    }


    const handleAlterHeader = (header) => {
        objects.splice(1, 1)

        setObjects([...objects, objects[0].header[0] = header]) 
    }

    const handlePushPlanPrev = (planPrev) => {
        objects.splice(1, 1)

        setObjects([...objects, objects[0].planPrev[0] = planPrev]) 
    }

    const AccountType = (value) => {
        switch(value){
            case 'D':
                return 'Depósito'
            case 'P':
                return 'Poupança'
            case 'N':
                return 'Numerário'
            case 'I':
                return 'Investimento'
            case 'J':
                return 'Judicial'
            default:
                return null;
        }
    }

    const LevelType = (value) => {
        switch(value){
            case 'BB':
                return 'Baixo'
            case 'MM':
                return 'Médio'
            case 'AA':
                return 'Alto'
            case 'MA':
                return 'Médio Alto'
            default:
                return null;
        }
    }

    const setInputNameValue = (name, newValue) => {
        if(headerList.indexOf(name) >= 0){ 
            setHeaderInput({[name]: newValue}) 
        } 
        if(caixaList.indexOf(name) >= 0){
            setCaixaInput({[name]: newValue})
        }
        if(planPrevList.indexOf(name) >= 0){
            setPlanPrevInput({[name]: newValue})
        }
    }

    const handleInputChange = e => { 
        setInputNameValue(e.target.name, e.target.value)  
    } 

    const handleNumberFields = e => { 
        const re = /^[0-9\b]+$/; 
        if (e.target.value === '' || re.test(e.target.value)) { 
            setInputNameValue(e.target.name, e.target.value) 
        } 
    }

    const handlePercFields = e => {
        const re = /^[-.,0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) { 
            setInputNameValue(e.target.name, e.target.value)  
        }
    }
    
    const handleDateFields = e => {
        const name = e.target.name; 
        const newValue = e.target.value; 
  
        const re = /^[-0-9\b]+$/;
  
        if (newValue === '' || re.test(newValue)) { 
          if(name === 'dtPosicao'){ 
            setHeaderInput({[name]: newValue}) 
          }  
        }
    }

    const handleRemoveCaixa = (index) => {
        objects[0]['depositos'].splice(index, 1)
  
        cleanCaixa()
    }

    const handleRemovePlanPrev = (index) => {
        objects[0]['planPrev'].splice(index, 1)
  
        cleanCaixa()
    }

    const handleTemplateDownload = () => {
        var templateName = 'modelo_secao_caixa'
        api.get(`template/${templateName}`, {responseType: 'arraybuffer'})
        .then(resp => { 
          if(window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${templateName}.xlsx`);
          } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${templateName}.xlsx`); 
              document.body.appendChild(link);
              link.click();
            }
        })
        .catch(e => {
            console.log(e);
        }); 
      }
    

    const jsonFix = (jsonFile) => {
        jsonFile = jsonFile.replace(/\w\d{2}\W\d{2}\W\d{2}\W\d{3}\w/gm, "")
        jsonFile = jsonFile.replace(/"__EMPTY":" ",/g,'')
        jsonFile = jsonFile.replace(/[\/]/g,'')
        jsonFile = jsonFile.replace(/[.]/g,',')

        return jsonFile
    }

    const handleFile = (e) => {
        if(e.target.files.length > 0){ 
            var files = e.target.files, f = files[0]; 
            var reader = new FileReader(); 
            reader.onload = function(e) { 
              var data = new Uint8Array(e.target.result); 
              var workbook = XLSX.read(data, {type: 'array'}) 
             
              var sheet_header = "header" 
              var sheet_depositos = "depositos"
              var sheet_planprev = "participacao de planos de prev."

              var worksheet = workbook.Sheets[sheet_header]; 
              var worksheet2 = workbook.Sheets[sheet_depositos];
              var worksheet3 = workbook.Sheets[sheet_planprev];
    
              var headerData = XLSX.utils.sheet_to_json(worksheet , {defval: ' '})
              var depositosData = XLSX.utils.sheet_to_json(worksheet2 , {defval: ' '})
              var planprevData = XLSX.utils.sheet_to_json(worksheet3 , {defval: ' '})

              var caixaData = { 
                    ['header']: headerData, 
                    ['depositos']: depositosData, 
                    ['planprev']: planprevData
              }

              var jsonFile = jsonFix(JSON.stringify(caixaData)) 

              api.post('/xmlCaixaTemplate/', jsonFile) 
              .then(resp => {  
                  console.log(resp.data.success) 
                  var fileName = resp.headers['file-name'] 
                  if(window.navigator.msSaveOrOpenBlob) { 
                    window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
                  } else { 
                    const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
                    const link = document.createElement('a'); 
                    link.href = url; 
                    link.setAttribute('download', fileName);  
                    document.body.appendChild(link); 
                    link.click(); 
                  } 
              }) 
              .catch(e => { 
                    console.log(e.response.data.error) 
              });
  
              document.getElementById("file-uploader").value = "";
            }; 
        reader.readAsArrayBuffer(f); 
        } 
    }

    const sendJson = () => { 
        var jsonData = JSON.stringify(objects) 
        jsonData = jsonData.replace(/[.]/g, ',')
   
        api.post('/xmlCaixa/', jsonData) 
        .then(resp => {  
            console.log(resp.data.success) 
            var fileName = resp.headers['file-name'] 
            if(window.navigator.msSaveOrOpenBlob) { 
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
            } else { 
              const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
              const link = document.createElement('a'); 
              link.href = url; 
              link.setAttribute('download', fileName);  
              document.body.appendChild(link); 
              link.click(); 
            } 
            
            setObjects([])
        }) 
        .catch(e => { 
            console.log(e.response.data.error) 
        }); 

        cleanInputs()
    };

    function cleanInputs(){  
       setHeaderInput({     
            cnpjCpf: '', 
            codCart: '', 
            dtPosicao: '',
            nomeCart: '', 
            tipoPessoa: '',
            codCntCor: '',
            nomeGestor: '',
            cnpjGestor: '',
            nomeCustodiante: '',
            cnpjCustodiante: '',
            patLiq: '',
            valorTributos: '',
            valorAtivos: '',
            valorReceber: '',
            valorPagar: ''
        });

        setCaixaInput({     
            isinInstituicao: '', 
            tipoConta: '', 
            saldo: '',
            nivelrsc: '' 
        });

        setPlanPrevInput({     
            cnpb: '', 
            percPart: ''
        })
    }

    function cleanCaixa(){
        setCaixaInput({     
            isinInstituicao: '', 
            tipoConta: '', 
            saldo: '',
            nivelrsc: '' 
        });
    }

    

    return (
    <div>
        <div className="panel panel-default"> 
            <div className="panel-heading clearfix"> 
                 
                <button className="btn btn-default btn-lg pull-right"
                onClick={() => {handleTemplateDownload()}}>
                <span className="fa fa-file-excel-o fa-1x align-left" style={{color:'green'}}>
                </span> 
                    Download Template
                </button> 
            </div> 
            <div className="panel-footer">
            {objects.length < 1 ? (
                <>
                <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#headerModal"><i className="glyphicon glyphicon-plus-sign"></i> Inserir Header</button> 
                
                <button type="button" className="btn btn-xs" disabled onClick={sendJson}><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button>
                </> )
            : (
                <>
                <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#headerModal"><i className="fa fa-edit"></i> Alterar Header</button> 
                
                <button type="button" className="btn btn-xs" onClick={sendJson}><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button>
                </> )      
            }
            
            {objects.length != 0 ? (
                <> 
                <button type="button" className="btn btn-xs" title={titleString} data-toggle="modal" data-target="#planPrevModal"><i className="fa fa-edit"></i> Adicionar/Alterar Plano de Participação de Prev. </button>
                
                <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#caixaModal"><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Caixa </button>  
                </> )
            : (
                <>
                <button type="button" disabled className="btn btn-xs"><i className="fa fa-edit"></i> Adicionar/Alterar Plano de Participação de Prev. </button> 
                
                <button type="button" disabled className="btn btn-xs"><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Caixa </button> 
                </> )
            }
                <label className="custom-file-upload" title="Para usar esta opção, faça o download do modelo acima. Após preenchido, envie o modelo através deste botão."> 
                    <input id="file-uploader" accept=".xlsx" type="file" onChange={handleFile} /> 
                    <i className="fa fa-upload"></i> Enviar template preenchido 
                </label> 
            </div> 
            <div className="panel panel-default"> 
            <table className="table table-striped"> 
                <thead className="thead-light"> 
                    <tr> 
                        <th scope="col" className="text-center">Visualizar</th>
                        <th scope="col" className="text-center">CNPJ/CPF</th> 
                        <th scope="col" className="text-center">Código da Carteira</th> 
                        <th scope="col" className="text-center">Data Posição</th> 
                        <th scope="col" className="text-center">Nome da Carteira</th>        
                        <th scope="col" className="text-center">Tipo de Pessoa</th> 
                        <th scope="col" className="text-center">Código da Conta Corrente</th>
                        <th scope="col" className="text-center">Visualizar Caixas</th> 
                    </tr> 
                </thead> 
                <tbody>             
                { objects[0] && objects[0].header.map(function(item, index){ 
                    return( 
                    <tr key={index}>  
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" type="button" data-toggle="modal" data-target="#headerView"
                            title="Visualização Geral"> 
                            <i className="fa fa-list-alt"></i></button> 
                            </span>
                        </td> 
                        <td className="text-center">{item.cnpjCpf}</td> 
                        <td className="text-center">{item.codCart}</td> 
                        <td className="text-center">{item.dtPosicao}</td> 
                        <td className="text-center">{item.nomeCart}</td> 
                        <td className="text-center">{item.tipoPessoa}</td> 
                        <td className="text-center">{item.codCntCor}</td>
                        <td className="text-center">
                            <span className="input-group-btn"> 
                            <button className="btn btn-default" type="button" data-toggle="modal" data-target="#caixaView"
                            title="Visualizar Caixa"> 
                            <i className="fa fa-eye"></i></button> 
                            </span>
                        </td>
                    </tr> 
                    )
                })}        
                </tbody> 
            </table>  
        </div>
        </div>
             
        


        {/* Modal to INSERT/EDIT Headers */}
        <div className="modal fade" id="headerModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Inserir Header</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                    <form id="input"> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">CNPJ ou CPF</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"  
                                maxLength="14"    
                                name="cnpjCpf"
                                placeholder="11 ou 14 dígitos"
                                value={headerInput.cnpjCpf}
                                onChange={handleNumberFields}/>
                                { headerInput.cnpjCpf.length < 11 ?
                                  <span className="help-block">Este campo precisa ter no minimo 11 dígitos (CPF) e no máximo 14 dígitos (CNPJ)</span>
                                  : null}
                            </div>
                        </div>
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Código da Carteira</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"      
                                name="codCart"
                                placeholder="Código da carteira"
                                value={headerInput.codCart}
                                onChange={handleInputChange}/>
                            </div>
                        </div>
                        </div> 
                        <div className='form-group'> 
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Data Posição</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"  
                                maxLength="10"    
                                name="dtPosicao"
                                placeholder="AAAA-MM-DD"
                                value={headerInput.dtPosicao}
                                onChange={handleDateFields}/>
                                <span className="help-block">AAAA-MM-DD</span>
                            </div>
                        </div>
                        </div>  
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Nome da Carteira</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control" 
                                placeholder="Nome do titular da carteira"     
                                name="nomeCart"
                                value={headerInput.nomeCart}
                                onChange={handleInputChange}/>
                            </div>
                        </div>
                        </div>
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Tipo Pessoa</label> 
                            </div> 
                            <div className="col-sm-8"> 
                            <select                                 
                                className="form-control" 
                                name="tipoPessoa" 
                                value={headerInput.tipoPessoa}
                                onChange={handleInputChange}>
                                <option value="" hidden></option> 
                                <option value="F">Pessoa Física</option> 
                                <option value="J">Pessoa Jurídica</option> 
                            </select>
                            </div>
                        </div>
                        </div> 
                        <div className="form-group">
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Código da Conta Corrente</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control" 
                                placeholder="Código para identificação da Carteira"     
                                name="codCntCor"
                                value={headerInput.codCntCor}
                                onChange={handleNumberFields}/>
                                <span className="help-block">Código do Banco + Código da
                                Agência sem o Dígito + Número da Conta Corrente sem o Dígito Verificador</span>
                            </div>
                        </div>
                        </div> 
                        <div className="form-group">
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Nome do Gestor</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"      
                                name="nomeGestor"
                                placeholder="Nome do Gestor/Administrador"
                                value={headerInput.nomeGestor}
                                onChange={handleInputChange}/>
                            </div>
                        </div>
                        </div>  
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">CNPJ do Gestor</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control" 
                                maxLength="14"
                                placeholder="CNPJ do gestor do fundo"     
                                name="cnpjGestor"
                                value={headerInput.cnpjGestor}
                                onChange={handleNumberFields}/>
                            </div>
                        </div>  
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Nome do Custodiante</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Nome do custodiante do fundo"      
                                name="nomeCustodiante"
                                value={headerInput.nomeCustodiante}
                                onChange={handleInputChange}/>
                            </div>
                        </div> 
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">CNPJ do Custodiante</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="CNPJ do custodiante do fundo"    
                                maxLength="14"  
                                name="cnpjCustodiante"
                                value={headerInput.cnpjCustodiante}
                                onChange={handleNumberFields}/>
                            </div>
                        </div> 
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">PL da Carteira</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Patrimônio Líq. de fechamento da Carteira"
                                name="patLiq"
                                value={headerInput.patLiq}
                                onChange={handlePercFields}/>
                                <span className="help-block">Valor dos Ativos + Valores a Receber – Valores a Pagar</span>
                            </div>
                        </div>  
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Valor de Tributos</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Valor total dos tributos provisionados"
                                name="valorTributos"
                                value={headerInput.valorTributos}
                                onChange={handlePercFields}/>
                            </div>
                        </div>                    
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Valor dos Ativos</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Valor dos ativos"
                                name="valorAtivos"
                                value={headerInput.valorAtivos}
                                onChange={handlePercFields}/>
                            </div>
                        </div>  
                        </div>
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Valor a Receber</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Valores a receber"
                                name="valorReceber"
                                value={headerInput.valorReceber}
                                onChange={handlePercFields}/>
                            </div>
                        </div> 
                        </div>
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Valor a Pagar</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"
                                placeholder="Valores a pagar"
                                name="valorPagar"
                                value={headerInput.valorPagar}
                                onChange={handlePercFields}/>
                            </div>
                        </div>  
                        </div>
                    </form>      
                    </div> 
                    <div className="modal-footer"> 
                    { objects.length < 1  ? ( 
                      <button  
                          type="button"  
                          className="btn btn-primary"  
                          data-dismiss="modal"   
                          onClick={() => handlePushHeader(headerInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                      </button> )
                        :
                      <button  
                         type="button"  
                         className="btn btn-primary"  
                         data-dismiss="modal"   
                         onClick={() => handleAlterHeader(headerInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Alterar 
                      </button> 
                    }
                    </div> 
                </div> 
            </div> 
        </div>
    
        {/* Modal for Headers view */}
        <div className="modal fade bs-example-modal-lg" id="headerView" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content-invest">  
                        <div className="panel panel-default"> 
                        <div className="panel-heading clearfix"> 
                            <h3 className="panel-title pull-left"> Informações do Header </h3> 
                        </div> 
                        <div className="panel-body"> 
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                        <thead className="thead-light">
                            <tr> 
                                <th scope="col" className="text-center">CNPJ/CPF</th> 
                                <th scope="col" className="text-center">Código da Carteira</th> 
                                <th scope="col" className="text-center">Data Posição</th> 
                                <th scope="col" className="text-center">Nome da Carteira</th>  
                                <th scope="col" className="text-center">Tipo de Pessoa</th> 
                                <th scope="col" className="text-center">Código da Conta Corrente</th>
                            </tr>   
                        </thead> 
                        <tbody>  
                            { objects[0] && objects[0].header.map(function(item, index){ 
                            return(         
                            <tr key={index}>  
                                <td className="text-center">{item.cnpjCpf}</td> 
                                <td className="text-center">{item.codCart}</td>
                                <td className="text-center">{item.dtPosicao}</td>
                                <td className="text-center">{item.nomeCart}</td>
                                <td className="text-center">{item.tipoPessoa}</td> 
                                <td className="text-center">{item.codCntCor}</td> 
                            </tr>  
                            )})}     
                        </tbody> 
                        </table>
                        </div>       
                        <div className="panel-body">
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" className="text-center">Nome do Gestor/Administrador</th>
                                <th scope="col" className="text-center">CNPJ do Gestor</th>
                                <th scope="col" className="text-center">Nome do Custodiante</th>
                                <th scope="col" className="text-center">CNPJ do Custodiante</th>
                                <th scope="col" className="text-center">PL da Carteira</th>
                                <th scope="col" className="text-center">Valor de Tributos</th>
                                <th scope="col" className="text-center">Valor dos Ativos</th>
                                <th scope="col" className="text-center">Valores a Receber</th>
                                <th scope="col" className="text-center">Valores a Pagar</th>
                            </tr>
                            </thead>
                            <tbody> 
                            { objects[0] && objects[0].header.map(function(item, index){ 
                            return(          
                            <tr key={index}>  
                                <td className="text-center">{item.nomeGestor}</td> 
                                <td className="text-center">{item.cnpjGestor}</td>
                                <td className="text-center">{item.nomeGestor}</td>
                                <td className="text-center">{item.cnpjCustodiante}</td>
                                <td className="text-center">{item.patLiq}</td> 
                                <td className="text-center">{item.valorTributos}</td> 
                                <td className="text-center">{item.valorAtivos}</td>
                                <td className="text-center">{item.valorReceber}</td>
                                <td className="text-center">{item.valorPagar}</td>
                            </tr> 
                            )})}       
                            </tbody>
                        </table>
                        </div>
                        <div className="panel-heading clearfix">
                            <h3 className="panel-title"> Participação de Planos de Previdência </h3>
                        </div>
                        <div className="panel-body">
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" className="text-center">CNPB</th>
                                <th scope="col" className="text-center">% da Participação</th>
                                <th scope="col" className="text-center">Remover Part. Plano de Prev.</th>
                            </tr>
                            </thead>
                            <tbody> 
                            { objects[0] && objects[0].planPrev.map(function(item, index){ 
                            return(          
                            <tr key={index}>  
                                <td className="text-center">{item.cnpb}</td> 
                                <td className="text-center">{item.percPart}%</td>
                                <td className="text-center">
                                    <span className="input-group-btn"> 
                                    <button className="btn btn-default" type="button" onClick={() => handleRemovePlanPrev(index)}> 
                                    <i className="fa fa-trash"></i></button> 
                                    </span>
                                </td> 
                            </tr> 
                            )})}       
                            </tbody>
                        </table>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
        {/* Modal to INSERT Caixa */}
        <div className="modal fade" id="caixaModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Inserir Caixa</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                    <form id="input"> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">ISIN Instituição</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"  
                                maxLength="14"    
                                name="isinInstituicao"
                                placeholder="Código ISIN da Inst. financeira depositária"
                                value={caixaInput.isinInstituicao}
                                onChange={handleInputChange}/>
                            </div>
                        </div>
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Tipo da Conta</label> 
                            </div> 
                            <div className="col-sm-8"> 
                            <select  
                                  name="tipoConta"  
                                  className="form-control"
                                  onChange={handleInputChange}
                                  value={caixaInput.tipoConta}>
                                    <option value="" hidden></option> 
                                    <option value="D">Depósito</option> 
                                    <option value="P">Poupança</option> 
                                    <option value="N">Numerário</option> 
                                    <option value="I">Investimento</option> 
                                    <option value="J">Judicial</option> 
                            </select> 
                            </div>
                        </div>
                        </div> 
                        <div className='form-group'> 
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Saldo Caixa</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"      
                                name="saldo"
                                placeholder="Valor do saldo"
                                value={caixaInput.saldo}
                                onChange={handlePercFields}/>
                            </div>
                        </div>
                        </div>  
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">Nível de Risco</label> 
                            </div> 
                            <div className="col-sm-8"> 
                            <select  
                                  name="nivelrsc"  
                                  className="form-control"
                                  onChange={handleInputChange}
                                  value={caixaInput.nivelrsc}>
                                    <option value="" hidden></option> 
                                    <option value="BB">Baixo</option> 
                                    <option value="MM">Médio</option> 
                                    <option value="AA">Alto</option> 
                                    <option value="MA">Médio Alto</option> 
                            </select> 
                            </div>
                        </div>
                        </div>
                    </form>      
                    </div> 
                    <div className="modal-footer"> 
                      <button  
                          type="button"  
                          className="btn btn-primary"  
                          data-dismiss="modal"   
                          onClick={() => handlePushCaixa(caixaInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                      </button>                
                    </div> 
                </div> 
            </div> 
        </div>

        {/* Modal to INSERT Plano Prev. */}
        <div className="modal fade" id="planPrevModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Inserir Plano de Participação de Prev.</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                    <form id="input"> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">CNPB</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"      
                                name="cnpb"
                                maxLength="10"
                                placeholder="10 Dígitos"
                                value={planPrevInput.cnpb}
                                onChange={handleNumberFields}/>
                            </div>
                        </div>
                        </div> 
                        <div className='form-group'>
                        <div className="row">  
                            <div className="col-sm-4 text-right"> 
                                <label className="control-label ">% da Participação</label> 
                            </div> 
                            <div className="col-sm-8"> 
                             <input type="text"   
                                className="form-control"      
                                name="percPart"
                                placeholder="Percentual da participação do Plano de Benefício"
                                value={planPrevInput.percPart}
                                onChange={handlePercFields}/>
                            </div>
                        </div>
                        </div>
                    </form>      
                    </div> 
                    <div className="modal-footer"> 
                      <button  
                          type="button"  
                          className="btn btn-primary"  
                          data-dismiss="modal"   
                          onClick={() => handlePushPlanPrev(planPrevInput)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                      </button>           
                    </div> 
                </div> 
            </div> 
        </div>

        {/* Modal for Caixa view */}
        <div className="modal fade bs-example-modal-lg" id="caixaView" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content-invest">  
                    <div className="panel panel-default"> 
                        <div className="panel-heading clearfix"> 
                            <h3 className="panel-title pull-left"> Informações do Depósito </h3> 
                        </div> 
                        <div className="panel-body"> 
                        <table border="1" bordercolor="#b28316" className="table table-striped "> 
                        <thead className="thead-light">
                            <tr> 
                                <th scope="col" className="text-center">ISIN da Instituição</th> 
                                <th scope="col" className="text-center">Tipo de Conta</th> 
                                <th scope="col" className="text-center">Saldo Caixa</th> 
                                <th scope="col" className="text-center">Nível de Risco</th> 
                                <th scope="col" className="text-center">Remover Caixa</th> 
                            </tr>   
                        </thead> 
                        <tbody>  
                            { objects[0] && objects[0].depositos && objects[0].depositos.length > 0 && objects[0].depositos.map(function(item, index){ 
                            return(         
                            <tr key={index}>  
                                <td className="text-center">{item.isinInstituicao}</td> 
                                <td className="text-center">{AccountType(item.tipoConta)}</td>
                                <td className="text-center">{item.saldo}</td>
                                <td className="text-center">{LevelType(item.nivelrsc)}</td>
                                <td className="text-center">
                                    <span className="input-group-btn"> 
                                    <button className="btn btn-default" type="button" onClick={() => handleRemoveCaixa(index)}> 
                                    <i className="fa fa-trash"></i></button> 
                                    </span>
                                </td> 
                            </tr>  
                            )})}     
                        </tbody> 
                        </table>
                        </div>
                    </div>        
                </div>
            </div>
        </div>

    </div>
    )
}

export default BoxSection;