import React, { useState, useReducer } from 'react'; 
 
import api from '../../services/api'; 
 
import XLSX from 'xlsx'; 
 
import './styles.css'; 
 
function FundsRegister(){ 
    //Header panel inputs 
    const headerList = ['cnpj', 'codCart', 'dtPosicao'] 
    const [headerInput, setHeaderInput] = useReducer( 
      (state, newState) => ({...state, ...newState}), 
      {     
        cnpj: '', 
        codCart: '', 
        dtPosicao: '', 
      } 
    ); 
 
 
    //Funds modal inputs 
    const [fundsInput, setFundsInput] = useReducer( 
      (state, newState) => ({...state, ...newState}), 
      {   
        isin: '', 
        cnpjFundo: '',  
        fundoExclusivo: '0', 
        fundoMultiMercadoEstruturado: '0',  

      } 
    ); 
 
    const [objects, setObjects] = useState([]); 
    const handlePushObjects = (header, fundos) => { 
  
      if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
          value: function(predicate) {
           // 1. Let O be ? ToObject(this value).
            if (this == null) {
              throw new TypeError('"this" is null or not defined');
            }
      
            var o = Object(this);
      
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
      
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
              throw new TypeError('predicate must be a function');
            }
      
            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];
      
            // 5. Let k be 0.
            var k = 0;
      
            // 6. Repeat, while k < len
            while (k < len) {
              // a. Let Pk be ! ToString(k).
              // b. Let kValue be ? Get(O, Pk).
              // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
              // d. If testResult is true, return k.
              var kValue = o[k];
              if (predicate.call(thisArg, kValue, k, o)) {
                return k;
              }
              // e. Increase k by 1.
              k++;
            }
      
            // 7. Return -1.
            return -1;
          },
          configurable: true,
          writable: true
        });
      }

      if(objects.findIndex(i => i.header)){ 
        setObjects([...objects,  {header, ['fundos']: [fundos]}]) 
      } else {
        setObjects([...objects, objects[0].fundos.push(fundos)]) 
      } 
 
      cleanInputs() 
    } 
    

    const handleInputChange = e => { 
      const name = e.target.name; 
      const newValue = e.target.value; 
 
      if(headerList.indexOf(name) >= 0){ 
        setHeaderInput({[name]: newValue}) 
      } else { 
        setFundsInput({[name]: newValue}); 
      }     
    } 
 
    const handleNumberFields = e => { 
      const name = e.target.name; 
      const newValue = e.target.value; 
 
      const re = /^[0-9\b]+$/; 
      if (newValue === '' || re.test(newValue)) { 
        if(headerList.indexOf(name) >= 0){ 
          setHeaderInput({[name]: newValue}) 
        } else { 
          setFundsInput({[name]: newValue}); 
        }   
      } 
    } 

    const dateRegex = /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/
    const handleDateFields = e => {
      const name = e.target.name; 
      const newValue = e.target.value; 

      const re = /^[-0-9\b]+$/;

      if (newValue === '' || re.test(newValue)) { 
        if(headerList.indexOf(name) >= 0){ 
          setHeaderInput({[name]: newValue}) 
        } else { 
          setFundsInput({[name]: newValue}); 
        }   
      }
    }
 
       
    const validateFunds = () => { 
      if(fundsInput.fundoMultiMercadoEstruturado !== '0' && fundsInput.fundoExclusivo !== '0' && fundsInput.cnpjFundo.length > 13)
      { 
        return true 
      } else { 
        return false 
      } 
    } 
    
    const validateHeaderForm = () => {
      if(headerInput.codCart.length > 0 || headerInput.cnpj.length > 13 && headerInput.dtPosicao.length > 9){
        if(dateRegex.test(headerInput.dtPosicao)){
          return true
        } else {
          return false
        }
      }
    }

    //Clean all modal inputs after 'Save' 
    function cleanInputs(){  
      setFundsInput({     
        isin: '', 
        cnpjFundo: '',  
        fundoExclusivo: '', 
        fundoMultiMercadoEstruturado: '',   
      })   
    } 

    const handleRemoveFunds = (index) => {
      objects[0]['fundos'].splice(index, 1)

      cleanInputs()
    }
 
    const sendJson = () => { 
      var header = headerInput
      setObjects([...objects, objects[0].header = header]) 

      var jsonData = JSON.stringify(objects) 
 
      api.post(`/xmlFundos/${jsonData}`) 
      .then(resp => {  
          console.log(resp.data.success) 
          var fileName = resp.headers['file-name'] 
          if(window.navigator.msSaveOrOpenBlob) { 
            window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
          } else { 
            const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
            const link = document.createElement('a'); 
            link.href = url; 
            link.setAttribute('download', fileName);  
            document.body.appendChild(link); 
            link.click(); 
          } 
 
          setHeaderInput(          
            {     
              cnpj: '', 
              codCart: '', 
              dtPosicao: '', 
            } 
          ); 
          setObjects([]) 
      }) 
      .catch(e => { 
          console.log(e.response.data.error) 
      }); 
 
    }; 
     
 
    // Download excel template 
    const handleTemplateDownload = () => { 
        var templateName = 'modelo_cadastro_de_fundos' 
        api.get(`template/${templateName}`, {responseType: 'arraybuffer'}) 
        .then(resp => {  
          if(window.navigator.msSaveOrOpenBlob) { 
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${templateName}.xlsx`); 
          } else { 
              const url = window.URL.createObjectURL(new Blob([resp.data])); 
              const link = document.createElement('a'); 
              link.href = url; 
              link.setAttribute('download', `${templateName}.xlsx`);  
              document.body.appendChild(link); 
              link.click(); 
            } 
        }) 
        .catch(e => { 
            console.log(e); 
        });  
    } 
   
    const jsonFix = (jsonFile) => {
      jsonFile = jsonFile.replace(/\w\d{2}\W\d{2}\W\d{2}\W\d{3}\w/gm, "")
      jsonFile = jsonFile.replace(/"__EMPTY":" ",/g,'')
      jsonFile = jsonFile.replace(/[\/]/g,'')
      jsonFile = jsonFile.replace(/[.]/g,',')

      return jsonFile
    }
    
    // Now working on BACKEND with the new template.
    const handleFile = (e) => {
      if(e.target.files.length > 0){ 
          var files = e.target.files, f = files[0]; 
          var reader = new FileReader(); 
          reader.onload = function(e) { 
            var data = new Uint8Array(e.target.result); 
            var workbook = XLSX.read(data, {type: 'array'}) 
           
            var sheet_header = "header" 
            var sheet_fundos = "fundos"

            var worksheet = workbook.Sheets[sheet_header]; 
            var worksheet2 = workbook.Sheets[sheet_fundos];
  
            var headerData = XLSX.utils.sheet_to_json(worksheet , {defval: ' '})
            var fundosData = XLSX.utils.sheet_to_json(worksheet2 , {defval: ' '})

            var fundsData = { 
                  ['header']: headerData, 
                  ['fundos']: fundosData, 
            }

            var jsonFile = jsonFix(JSON.stringify(fundsData)) 

            api.post('/xmlFundosTemplate/', jsonFile) 
            .then(resp => {  
                console.log(resp.data.success) 
                var fileName = resp.headers['file-name'] 
                if(window.navigator.msSaveOrOpenBlob) { 
                  window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
                } else { 
                  const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
                  const link = document.createElement('a'); 
                  link.href = url; 
                  link.setAttribute('download', fileName);  
                  document.body.appendChild(link); 
                  link.click(); 
                } 
            }) 
            .catch(e => { 
                  alert("Favor, realize o download e preenchimento do novo modelo da planilha no botão 'Download Template'.") 
            });

            document.getElementById("file-uploader").value = "";
          }; 
      reader.readAsArrayBuffer(f); 
      } 
  }
 
    return ( 
        <div>
        <div className="panel panel-default"> 
            <div className="panel-heading clearfix"> 
            <div className="row">
                <span className="help-block pull-right" style={{marginRight:'30px', color:'red'}}> Planilha Atualizada 03/02/2022</span>
            </div>
            <h3 className="panel-title pull-left"> Informe os dados da Entidade </h3> 
                <button onClick={() => {handleTemplateDownload()}} className="btn btn-default btn-lg pull-right"><span className="fa fa-file-excel-o fa-1x align-left" style={{color:'green'}}></span> Download Template</button> 
            </div> 
            <div className="panel-body"> 
                <form> 
                    <div className={headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ? 'form-group col-md-4 has-error' : 'form-group col-md-4'}> 
                        <label className="control-label">CNPJ</label> 
                        <input  
                        type="text"  
                        name="cnpj" 
                        value={headerInput.cnpj} 
                        className="form-control" 
                        placeholder="CNPJ do titular" 
                        maxLength="14" 
                        onChange={handleNumberFields}
                        disabled/>
                        
                        { headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ?
                          <span className="help-block">O CNPJ precisa ter 14 dígitos</span>
                        : null} 
                    </div> 
                    <div className="form-group col-md-4"> 
                        <label>Código da Carteira</label> 
                        <input  
                        type="text" 
                        name="codCart" 
                        placeholder="Código carteira" 
                        value={headerInput.codCart}  
                        className="form-control"  
                        onChange={handleInputChange} 
                        disabled/> 
                    </div> 
                    <div className={headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ? "form-group col-md-4 has-error" : "form-group col-md-4"}> 
                        <label className="control-label">Data da Posição</label> 
                        <input type="text" 
                        className="form-control"  
                        name="dtPosicao" 
                        value={headerInput.dtPosicao} 
                        placeholder="AAAA-MM-DD" 
                        maxLength="10" 
                        onChange={handleDateFields}
                        disabled/> 
                        { headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ?
                          <span className="help-block">Insira a data no seguinte no formato:  AAAA-MM-DD</span>
                        : null }  
                    </div> 
                </form> 
            </div> 
            <div className="panel-footer">                
                  {
                    (objects.length > 0 && validateHeaderForm()) ?
                    <button type="button" onClick={sendJson} className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
                    : //Conditional Render
                    <button type="button" disabled className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
                  }
                  {
                    (validateHeaderForm()) ?                     
                    <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar fundo</button> 
                    : //Conditional Render           
                    <button type="button" disabled className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar fundo</button>
                  }       
 
                <label className="custom-file-upload" title="Para usar esta opção, faça o download do modelo acima. Após preenchido, envie o modelo através deste botão."> 
                    <input id="file-uploader" accept=".xlsx" type="file" onChange={handleFile}/> 
                    <i className="fa fa-upload"></i> Enviar template preenchido 
                </label> 
            </div> 
        </div>  
     
        <div className="panel panel-default"> 
        <table className="table table-striped"> 
            <thead className="thead-light"> 
                <tr> 
                    <th scope="col" className="text-center">ISIN</th> 
                    <th scope="col" className="text-center">CNPJ</th> 
                    <th scope="col" className="text-center">Exclusivo</th> 
                    <th scope="col" className="text-center">Multimercado Estruturado</th> 
                </tr> 
            </thead> 
            <tbody> 
            { objects[0] && objects[0].fundos.map(function(item, index){ 
                return( 
                  <tr key={index}> 
                      <td className="text-center">{item.isin}</td> 
                      <td className="text-center">{item.cnpjFundo}</td> 
                      <td className="text-center">{item.fundoExclusivo === '1' ? 'Sim' : 'Não'}</td> 
                      <td className="text-center">{item.fundoMultiMercadoEstruturado === '1' ? 'Sim' : 'Não'}</td> 
                      <td className="text-center">
                        <span className="input-group-btn"> 
                          <button className="btn btn-default" type="button" onClick={() => handleRemoveFunds(index)}> 
                          <i className="fa fa-trash"></i></button> 
                        </span>
                      </td>
                  </tr> 
                ) 
              })}         
             </tbody> 
            </table> 
        </div> 
         
        {/* Modal for Funds register */}
        <div className="modal fade" id="FundoModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Adicionar Fundo</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                    <form id="input"> 
                      <div className="form-group">
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label">ISIN</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <input type="text"  
                                  placeholder="Código fornecido pela B3" 
                                  className="form-control"  
                                  name="isin"  
                                  value={fundsInput.isin}  
                                  onChange={handleInputChange}/> 
                              </div> 
                          </div> 
                        </div> 
                        <div className={fundsInput.cnpjFundo.length >= 1 && fundsInput.cnpjFundo.length < 14 ? 'form-group  has-error' : 'form-group'}>
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">CNPJ</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <input type="text"  
                                  placeholder="CNPJ do fundo" 
                                  className="form-control"  
                                  maxLength="14" 
                                  onChange={handleNumberFields}  
                                  name="cnpjFundo"  
                                  value={fundsInput.cnpjFundo} />
                                  { fundsInput.cnpjFundo.length >= 1 && fundsInput.cnpjFundo.length < 14 ?
                                  <span className="help-block">O CNPJ precisa ter 14 dígitos</span>
                                  : null} 
                              </div> 
                            </div>
                        </div> 
                        <div className="form-group">
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">Exclusivo ?</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <select  
                                  name="fundoExclusivo"                                  
                                  className="form-control" 
                                  onChange={handleNumberFields}  
                                  value={fundsInput.fundoExclusivo}> 
                                    <option value="" hidden></option> 
                                    <option value="1">Sim</option> 
                                    <option value="2">Não</option> 
                                  </select> 
                              </div> 
                          </div>  
                        </div> 
                        <div className="form-group">
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">Multimercado Estruturado ?</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <select  
                                  name="fundoMultiMercadoEstruturado"  
                                  className="form-control" 
                                  onChange={handleNumberFields} 
                                  value={fundsInput.fundoMultiMercadoEstruturado}> 
                                    <option value="" hidden></option> 
                                    <option value="1">Sim</option> 
                                    <option value="2">Não</option> 
                                  </select> 
                              </div> 
                          </div>  
                        </div>  
                    </form>      
                    </div> 
                    <div className="modal-footer"> 
                        { validateFunds() ? ( 
                        <button  
                          type="button"  
                          className="btn btn-primary" 
                          onClick={() => handlePushObjects(headerInput, fundsInput)}  
                          data-dismiss="modal"> 
                          <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                        </button> )  
                        :  
                        <button  
                          type="button"  
                          disabled 
                          className="btn btn-primary"  
                          data-dismiss="modal">                          
                          <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                        </button> 
                        } 
                    </div> 
                </div> 
            </div> 
        </div>           
       </div> 
      ) 
       
  } 
   
export default FundsRegister; 