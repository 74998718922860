import React, { useState } from "react";

import "./style.css";

import api from "../../services/api";

import TableContainer from "./TableContainer";
import InputsDate from "./InputsDate";
import Loading from "./Loading";
import EmptyReport from "./EmptyReport";

const ReportPage = () => {
  const [reports, setReports] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const dateFormated = (dateParam) => {
    const date = dateParam,
      day = date.getDate().toString(),
      dayF = day.length === 1 ? "0" + day : day,
      month = (date.getMonth() + 1).toString(),
      monthF = month.length === 1 ? "0" + month : month,
      yearF = date.getFullYear();
    return `${yearF}-${monthF}-${dayF}`;
  };
  const getReports = async () => {
    try {
      const firstDate = dateFormated(initialDate);
      const lastDate = dateFormated(finalDate);
      setLoading(true);
      const { data } = await api.post("/getReports", {
        initialDate: firstDate,
        finalDate: lastDate,
      });
      setReports(data.reports);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      <InputsDate
        initialDate={initialDate}
        finalDate={finalDate}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        getReports={getReports}
      />

      {reports.length === 0 && !loading && <EmptyReport />}

      {loading ? (
        <Loading />
      ) : (
        reports.length !== 0 && <TableContainer reports={reports} />
      )}
    </>
  );
};

export default ReportPage;
