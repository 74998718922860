import React from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../actions/authAction'

import logo_nq from '../../assets/images/chart.png';


function NavBar(){
    const dispatch = useDispatch();
    const userIsAdmin = useSelector(state => state.auth.credentials.user_admin);

    return(
        <div>
        <nav className="navbar navbar-default navbar-fixed-top navbar-style" role="navigation">

            <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>

                <Link to="/" className="navbar-brand">
                    <img src={logo_nq} alt="LOGO" width="150" height="57"/>
                </Link>
            </div>

            <ul className="nav navbar-left top-nav">
               {/* <li>
                    <Link to="/password" className="nav-link"><span className="fa fa-cog" title="Alterar senha"></span></Link>
                </li>
                */}
                {userIsAdmin ? 
                    <li>
                        <Link to="/admin/users" className="nav-link"><span className="fa fa-users" title="Gerenciar usuários"></span></Link>
                    </li>
                         
                    : null
                } 
                {userIsAdmin ?
                    <li>
                    <Link to="/admin/reports" className="nav-link"><i className="fa fa-file" title="Relatórios"></i></Link>
                    </li>
                    : null
                } 
                 
                </ul>   
                <ul className="nav navbar-right top-nav">
                <li><a href="JavaScript:Void(0)" onClick={() => dispatch(logoutAction())}><i className="fa fa-fw fa-power-off"></i> Sair</a></li>
                    
                </ul>

            </nav>
            <br/><br/><br/>
            </div>
    )
}

export default NavBar;