import React from "react";

const TableContainer = ({ reports }) => {
  const parseDatePTBR = (date) => {
    const day = date.split("-")[2];
    const month = date.split("-")[1];
    const year = date.split("-")[0];

    const fullDate = `${day}/${month}/${year}`;
    return fullDate;
  };

  return (
    <div className="container-table">
      <table className="report-table">
        <thead className="thead-dark report-thead">
          <tr className="report-tr">
            <th>Usuário</th>
            <th>Data</th>
            <th>Horário</th>
          </tr>
        </thead>

        <tbody>
          {reports.map((report) => (
            <tr className="report-tr" key={report.id}>
              <td className="text-center">{report.userLogin}</td>
              <td className="text-center">
                {parseDatePTBR(report.downloadAt.slice(0, 10))}
              </td>
              <td className="text-center">{report.downloadAt.slice(11, 19)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableContainer;
