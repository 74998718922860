import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import Reducers from '../reducers';

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
&& window.__REDUX_DEVTOOLS_EXTENSION__()

const Store = applyMiddleware(thunkMiddleware)(createStore)(Reducers,devTools); 

export default Store
