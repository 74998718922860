export const resetForm = (formId) =>{
    document.getElementById(formId).reset();
  };

export const getNumMonth = (month) => {
    let m = month.toLowerCase();
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }
      
    if(m.startsWith("jan")){
        return "01";
    }
    if(m.startsWith("fev")){
        return "02";
    }
    if(m.startsWith("mar")){
        return "03";
    }
    if(m.startsWith("abr")){
        return "04";
    }
    if(m.startsWith("mai")){
        return "05";
    }
    if(m.startsWith("jun")){
        return "06";
    }
    if(m.startsWith("jul")){
        return "07";
    }
    if(m.startsWith("ago")){
        return "08";
    }
    if(m.startsWith("set")){
        return "09";
    }
    if(m.startsWith("out")){
        return "10";
    }
    if(m.startsWith("nov")){
        return "11";
    }
    if(m.startsWith("dez")){
        return "12";
    }
};

