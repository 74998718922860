import React, { useState, useReducer } from 'react'; 

import XLSX from 'xlsx'; 

import api from '../../services/api';

function OperationParticipants(){
    const dateRegex = /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/
    const headerList = ['cnpj', 'codCart', 'dtPosicao']
    const indexadores = [
      'SHR','AFN','ANB','BDR','SB1','SB2','CDI','DOL','DOW','EUR','FG1','FGE','IBA','IBO','IBr','IBX','IB5','ICB','ICO','ICN','ICV','IDA','IDD','IDG','IDP','IDM','IDC','IEI','IDI','IDE','IDV','I10','I15','I20','I01','I30','I03','I05','ID1','ID2','ID3','I3M','ID5','IEE','JPY','IFX','IFM','IMM','IFN','IGC','IGN','IGT','IGX','IGI','IG1','IGD','IGM','IHF','IMG','IGE','IMB','IB1','IMA','IB2','IMC','IC1','IC2','IMS','IMT','IMO','INC','SPB','SPM','SPR','SPQ','SPA','SPI','IND','INP','INS','IPA','IPC','ICA','IP1','IPE','IPI','IPB','IPF','IRM','IRF','IR1','IM1','ISE','ITA','ITE','IVB','MLC','MSB','MSW','OZ1','MXN','POU','PRE','PTA','SPP','SPC','SPJ','SAL','SCC','SCS','SEL','SM','SML','TER','TBF','BSR','TPN','TJL','TR','UTI'
  ]  

    const [headerInput, setHeaderInput] = useReducer( 
      (state, newState) => ({...state, ...newState}), 
      {     
        cnpj: '', 
        codCart: '', 
        dtPosicao: '', 
      } 
    );

    const [opParts, setOpParts] = useReducer( 
      (state, newState) => ({...state, ...newState}), 
      {     
        tipoOpPart: '',
        cnpb: '',
        indexador: '',
        percIndex: '',
        txOperacao: '',
        vlVencido: '',
        vlaVencer: '',
        percProvCred: ''
      } 
    );

    const [objects, setObjects] = useState([]);
    const handlePushObjects = (header, opParticipants) => {
      // Fix for IE(Internet explorer)
      if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
          value: function(predicate) {
           // 1. Let O be ? ToObject(this value).
            if (this == null) {
              throw new TypeError('"this" is null or not defined');
            }
      
            var o = Object(this);
      
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
      
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
              throw new TypeError('predicate must be a function');
            }
      
            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];
      
            // 5. Let k be 0.
            var k = 0;
      
            // 6. Repeat, while k < len
            while (k < len) {
              // a. Let Pk be ! ToString(k).
              // b. Let kValue be ? Get(O, Pk).
              // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
              // d. If testResult is true, return k.
              var kValue = o[k];
              if (predicate.call(thisArg, kValue, k, o)) {
                return k;
              }
              // e. Increase k by 1.
              k++;
            }
      
            // 7. Return -1.
            return -1;
          },
          configurable: true,
          writable: true
        });
      }

      if(objects.findIndex(i => i.header)){ 
        setObjects([...objects,  {header, ['operpart']: [opParticipants]}]) 
      } else {
        setObjects([...objects, objects[0].operpart.push(opParticipants)]) 
      }

      cleanInputs();
    }


    const handleInputChange = e => { 
      const name = e.target.name; 
      const newValue = e.target.value; 
 
      if(headerList.indexOf(name) >= 0){ 
        setHeaderInput({[name]: newValue}) 
      } else { 
        setOpParts({[name]: newValue}); 
      }     
    } 

    const handleNumberFields = e => { 
      const name = e.target.name; 
      const newValue = e.target.value; 
 
      const re = /^[0-9\b]+$/; 
      if (newValue === '' || re.test(newValue)) { 
        if(headerList.indexOf(name) >= 0){ 
          setHeaderInput({[name]: newValue}) 
        } else { 
          setOpParts({[name]: newValue})
        }   
      } 
    }

    const handleDateFields = e => {
      const name = e.target.name; 
      const newValue = e.target.value; 

      const re = /^[-0-9\b]+$/;

      if (newValue === '' || re.test(newValue)) { 
        if(name === 'dtPosicao'){ 
          setHeaderInput({[name]: newValue}) 
        }  
      }
    }

    const handlePercFields = e => {
      const name = e.target.name; 
      const newValue = e.target.value; 

      const re = /^[-.,0-9\b]+$/;

      if (newValue === '' || re.test(newValue)) { 
        setOpParts({[name]: newValue})    
      }
    }

    function cleanInputs(){  
      setOpParts({     
        tipoOpPart: '',
        cnpb: '',
        indexador: '',
        percIndex: '',
        txOperacao: '',
        vlVencido: '',
        vlaVencer: '',
        percProvCred: ''
      })
    }

    const handleRemoveOpParts = (index) => {
      objects[0]['operpart'].splice(index, 1)

      cleanInputs()
    }

    const sendJson = () => { 
      var header = headerInput
      setObjects([...objects, objects[0].header = header]) 

      var jsonData = JSON.stringify(objects) 
      jsonData = jsonData.replace(/[.]/g, ',')
 
      api.post(`/xmlOperPart/`, jsonData) 
      .then(resp => {  
          console.log(resp.data.success) 
          var fileName = resp.headers['file-name'] 
          if(window.navigator.msSaveOrOpenBlob) { 
            window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
          } else { 
            const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
            const link = document.createElement('a'); 
            link.href = url; 
            link.setAttribute('download', fileName);  
            document.body.appendChild(link); 
            link.click(); 
          } 
 
          setHeaderInput(          
            {     
              cnpj: '', 
              codCart: '', 
              dtPosicao: '', 
            } 
          ); 
          setObjects([]) 
      }) 
      .catch(e => { 
          console.log(e.response.data.error) 
      }); 
 
    }; 
   
    const handleTemplateDownload = () => {
        var templateName = 'modelo_operacao_com_participantes'
        api.get(`template/${templateName}`, {responseType: 'arraybuffer'})
        .then(resp => { 
          if(window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${templateName}.xlsx`);
          } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${templateName}.xlsx`); 
              document.body.appendChild(link);
              link.click();
            }
        })
        .catch(e => {
            console.log(e);
        }); 
      }

      const jsonFix = (jsonFile) => {
        jsonFile = jsonFile.replace(/\w\d{2}\W\d{2}\W\d{2}\W\d{3}\w/gm, "")
        jsonFile = jsonFile.replace(/"__EMPTY":" ",/g,'')
        jsonFile = jsonFile.replace(/[\/]/g,'')
        jsonFile = jsonFile.replace(/[.]/g,',')
  
        return jsonFile
      }

      const handleFile = (e) => { 
        if(e.target.files.length > 0){ 
          var files = e.target.files, f = files[0]; 
          var reader = new FileReader(); 
          reader.onload = function(e) { 
            var data = new Uint8Array(e.target.result); 
            var workbook = XLSX.read(data, {type: 'array'}) 
           
            var sheet_header = "header" 
            var sheet_opParts = "op. participantes"

            var worksheet = workbook.Sheets[sheet_header]; 
            var worksheet2 = workbook.Sheets[sheet_opParts];
  
            var headerData = XLSX.utils.sheet_to_json(worksheet , {defval: ' '})
            var opParticipantesData = XLSX.utils.sheet_to_json(worksheet2 , {defval: ' '})

            var opPartsData = { 
                  ['header']: headerData, 
                  ['opparts']: opParticipantesData, 
            }

            var jsonFile = jsonFix(JSON.stringify(opPartsData))
 
            api.post('/xmlOperPartTemplate/', jsonFile) 
            .then(resp => {  
                console.log(resp.data.success) 
                var fileName = resp.headers['file-name'] 
                if(window.navigator.msSaveOrOpenBlob) { 
                  window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), fileName); 
                } else { 
                  const url = window.URL.createObjectURL(new Blob([resp.data]), fileName); 
                  const link = document.createElement('a'); 
                  link.href = url; 
                  link.setAttribute('download', fileName);  
                  document.body.appendChild(link); 
                  link.click(); 
                } 
            }) 
            .catch(e => { 
                  console.log(e.response.data.error) 
            });

            document.getElementById("file-uploader").value = "";
          }; 
          reader.readAsArrayBuffer(f); 
        } 
      } 

      const validateHeaderForm = () => {
        if(headerInput.codCart.length > 0 || headerInput.cnpj.length > 13 && headerInput.dtPosicao.length > 9){
          if(dateRegex.test(headerInput.dtPosicao)){
            return true
          } else {
            return false
          }
        }
      }

      const validateOperPartForm = () => {
        if(opParts.tipoOpPart === '' || opParts.cnpb.length < 10 || opParts.indexador === '' ||opParts.percIndex === '' || opParts.txOperacao === ''){
          return false
        } else {
          return true
        }
      } 

      return ( 
      <div> 
        <div className="panel panel-default"> 
            <div className="panel-heading clearfix"> 
            <h3 className="panel-title pull-left"> Informe os dados da Entidade </h3> 
                <button onClick={() => {handleTemplateDownload()}} className="btn btn-default btn-lg pull-right"><span className="fa fa-file-excel-o fa-1x align-left" style={{color:'green'}}></span> Download Template</button> 
            </div> 
            <div className="panel-body"> 
            <form> 
                    <div className={headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ? 'form-group col-md-4 has-error' : 'form-group col-md-4'}> 
                        <label className="control-label">CNPJ</label> 
                        <input  
                        type="text"  
                        name="cnpj" 
                        value={headerInput.cnpj} 
                        className="form-control" 
                        placeholder="CNPJ do titular" 
                        maxLength="14" 
                        onChange={handleNumberFields}/>
                        { headerInput.cnpj.length >= 1 && headerInput.cnpj.length < 14 ?
                          <span className="help-block">O CNPJ precisa ter 14 dígitos</span>
                        : null} 
                    </div> 
                    <div className="form-group col-md-4"> 
                        <label>Código da Carteira</label> 
                        <input  
                        type="text" 
                        name="codCart" 
                        placeholder="Código carteira" 
                        value={headerInput.codCart}  
                        className="form-control"  
                        onChange={handleInputChange} /> 
                    </div> 
                    <div className={headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ? "form-group col-md-4 has-error" : "form-group col-md-4"}> 
                        <label className="control-label">Data da Posição</label> 
                        <input type="text" 
                        className="form-control"  
                        name="dtPosicao" 
                        value={headerInput.dtPosicao} 
                        placeholder="AAAA-MM-DD" 
                        maxLength="10" 
                        onChange={handleDateFields}/> 
                        { headerInput.dtPosicao.length >= 1 && headerInput.dtPosicao.length < 10 ?
                          <span className="help-block">Insira a data no seguinte no formato:  AAAA-MM-DD</span>
                        : null }  
                    </div> 
                </form>
            </div> 
            <div className="panel-footer">                
            {
                (objects.length > 0 && validateHeaderForm()) ?
                  <button type="button" onClick={sendJson} className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
                    : //Conditional Render
                  <button type="button" disabled className="btn btn-xs"><i className="glyphicon glyphicon-floppy-save"></i> Gerar Arquivo XML</button> 
                }
                {
                (validateHeaderForm()) ?                     
                  <button type="button" className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Op.Participante</button> 
                  : //Conditional Render           
                  <button type="button" disabled className="btn btn-xs" data-toggle="modal" data-target="#FundoModal" ><i className="glyphicon glyphicon-plus-sign"></i> Adicionar Op.Participante</button>
                }            
                <label className="custom-file-upload" title="Para usar esta opção, faça o download do modelo acima. Após preenchido, envie o modelo através deste botão."> 
                    <input id="file-uploader" accept=".xlsx" type="file" onChange={handleFile} /> 
                    <i className="fa fa-upload"></i> Enviar template preenchido 
                </label> 
            </div> 

            <div className="modal fade" id="FundoModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
            <div className="modal-dialog"> 
                <div className="modal-content"> 
                    <div className="modal-header"> 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> 
                        <h4 className="modal-title" id="myModalLabel">Adicionar Op.Participante</h4> 
                    </div> 
                    <div className="modal-body-fundo">  
                    <form id="input"> 
                      <div className={opParts.tipoOpPart === '' ? 'form-group  has-error' : 'form-group'}>
                        <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">Tipo de Operação</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <select  
                                  name="tipoOpPart"  
                                  className="form-control"
                                  onChange={handleInputChange}
                                  value={opParts.tipoOpPart}>
                                    <option value="" hidden></option> 
                                    <option value="1">Empréstimos</option> 
                                    <option value="2">Financiamento Imobiliário</option> 
                                  </select> 
                              </div> 
                          </div>
                        </div> 
                        <div className={opParts.cnpb.length < 10 ? 'form-group  has-error' : 'form-group'}>
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">CNPB</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <input type="text"   
                                  className="form-control"  
                                  maxLength="10"    
                                  name="cnpb"
                                  placeholder="10 Dígitos"
                                  value={opParts.cnpb}
                                  onChange={handleNumberFields}/> 
                                  { opParts.cnpb.length >= 1 && opParts.cnpb.length < 10 ?
                                  <span className="help-block">O CNPB precisa ter 10 dígitos</span>
                                  : null}
                              </div> 
                            </div>
                        </div> 
                        <div className={opParts.indexador === '' ? 'form-group  has-error' : 'form-group'}> 
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label ">Indexador</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                  <select  
                                  name="indexador"  
                                  className="form-control"
                                  onChange={handleInputChange}
                                  value={opParts.indexador}> 
                                    <option value="" hidden></option>
                                    {indexadores.sort().map((x) => <option value={x} key={x}>{x}</option>)}
                                  </select> 
                              </div> 
                          </div>
                        </div>  
                        <div className={opParts.percIndex === '' ? 'form-group  has-error' : 'form-group'}>
                          <div className="row">  
                              <div className="col-sm-4 text-right"> 
                                  <label className="control-label">Indexador (Percentual)</label> 
                              </div> 
                              <div className="col-sm-8"> 
                                <div className="input-group">
                                    <input type="text"                                  
                                    className="form-control" 
                                    name="percIndex" 
                                    placeholder="Indexador da operação"
                                    value={opParts.percIndex}
                                    onChange={handlePercFields}/> 
                                    <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                </div> 
                                <span className="help-block">Use ponto ( . )  ou  vírgula ( , ) como separador decimal</span>
                              </div>
                          </div>  
                        </div>
                        <div className={opParts.txOperacao === '' ? 'form-group  has-error' : 'form-group'}>
                          <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Taxa da Operação </label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <input type="text"   
                                    placeholder="Atributo para indicar a Taxa"                
                                    className="form-control" 
                                    name="txOperacao"
                                    value={opParts.txOperacao}
                                    onChange={handlePercFields}/> 
                                </div> 
                            </div>   
                        </div> 
                        <div className="form-group">
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Valor Vencido </label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <input type="text"                                  
                                    className="form-control" 
                                    name="vlVencido"
                                    placeholder="Atributo para indicar o valor já vencido"
                                    value={opParts.vlVencido}
                                    onChange={handlePercFields}/> 
                                </div> 
                            </div>  
                        </div> 
                        <div className="form-group">
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Valor a Vencer </label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <input type="text"                                  
                                    className="form-control" 
                                    placeholder="Atributo para indicar o valor a vencer"
                                    name="vlaVencer"
                                    value={opParts.vlaVencer}
                                    onChange={handlePercFields}/>
                                </div> 
                            </div>  
                        </div>  
                        <div className='form-group'>
                            <div className="row">  
                                <div className="col-sm-4 text-right"> 
                                    <label className="control-label">Provisção de crédito </label> 
                                </div> 
                                <div className="col-sm-8"> 
                                    <div className="input-group">
                                      <input type="text"                                  
                                      className="form-control" 
                                      name="percProvCred"
                                      title="Provisão de perda percentual sobre o valor financeiro bruto disponível
                                      “Valor da Carteira de Empréstimos a Participantes”."
                                      value={opParts.percProvCred}
                                      onChange={handlePercFields}/> 
                                      <span className="input-group-addon" style={{fontWeight: 'bold', fontSize: '15px'}}>%</span>
                                    </div>
                                  <span className="help-block">Use ponto( . )  ou  vírgula ( , ) como separador decimal</span>
                                </div> 
                            </div>  
                        </div> 
                    </form>      
                    </div> 
                    <div className="modal-footer"> 
                    { validateOperPartForm() ? ( 
                      <button  
                          type="button"  
                          className="btn btn-primary"  
                          data-dismiss="modal"   
                          onClick={() => handlePushObjects(headerInput, opParts)}> <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                      </button> )
                        :
                      <button  
                          type="button"  
                          disabled 
                          className="btn btn-primary"  
                          data-dismiss="modal">                          
                          <i className="glyphicon glyphicon-floppy-saved"/> Salvar 
                      </button> 
                    }
                    </div> 
                </div> 
            </div> 
        </div>
        </div>  
     
        <div className="panel panel-default"> 
        <table className="table table-striped"> 
            <thead className="thead-light"> 
                <tr> 
                    <th scope="col" className="text-center">Tipo da Operação</th> 
                    <th scope="col" className="text-center">CNPB</th> 
                    <th scope="col" className="text-center">Indexador</th> 
                    <th scope="col" className="text-center">Percentual do Indexador(%)</th> 
                    <th scope="col" className="text-center">Taxa da Operação</th> 
                    <th scope="col" className="text-center">Valor Vencido</th> 
                    <th scope="col" className="text-center">Valor a Vencer</th> 
                    <th scope="col" className="text-center">Provisão de Crédito(%)</th> 
                </tr> 
            </thead> 
            <tbody>          
              { objects[0] && objects[0].operpart.map(function(item, index){ 
                return( 
                  <tr key={index}>  
                      <td className="text-center">{item.tipoOpPart === '1' ? 'Empréstimos' : 'Financiamento Imobiliário'}</td> 
                      <td className="text-center">{item.cnpb}</td> 
                      <td className="text-center">{item.indexador}</td> 
                      <td className="text-center">{item.percIndex} %</td> 
                      <td className="text-center">{item.txOperacao}</td> 
                      <td className="text-center">{item.vlVencido}</td> 
                      <td className="text-center">{item.vlaVencer}</td>
                      <td className="text-center">{item.percProvCred} %</td> 
                      <td className="text-center">
                        <span className="input-group-btn"> 
                          <button className="btn btn-default" type="button" onClick={() => handleRemoveOpParts(index)}> 
                          <i className="fa fa-trash"></i></button> 
                        </span>
                      </td> 
                  </tr> 
                ) 
              })}        
            </tbody> 
          </table> 
        </div> 
      </div>
      )
  }
  
export default OperationParticipants;