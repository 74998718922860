import React from 'react';
import { Link } from 'react-router-dom';
import  ScrollToTop  from  "react-scroll-up";


import parallax_bg11 from '../../assets/images/parallax-bg11.jpg';
import logo_nq from '../../assets/images/logo_nq.png';
import up_arrow from '../../assets/images/up_arrow_round.png';



function Folioview(){
    return(
        <div className="site_wrapper">
            <div className="topbar light">
                <div className="container">
                    <div className="topbar-left-items">
                        <div className="margin-top1"></div>
                        <ul className="toplist toppadding pull-left paddtop1">
                            <li className="rightl"></li>
                        </ul>
                    </div>
            
            
                    <div className="topbar-right-items pull-right">
                        <ul className="toplist toppadding">
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className="clearfix"></div>
            
            <div className="clearfix"></div>
            
            
            <div id="header">
                <div className="container">
                    <div className="navbar navbar-default yamm">
                        <div className="navbar-header">
                            <button type="button" data-toggle="collapse" data-target="#navbar-collapse-grid" className="navbar-toggle two three"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                            <Link to="/" className="navbar-brand"><img src={logo_nq} alt=""/></Link>
                        </div><br/>
                        <div id="navbar-collapse-grid" className="navbar-collapse collapse pull-right">
                            <ul className="nav navbar-nav">
                            <li className="dropdown"> <Link to="/" className="dropdown-toggle">Home</Link> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        <div className="clearfix"></div>

        <section>
            <div className="header-inner two">
            <div className="inner text-center">
                <h4 className="title text-white uppercase">Folioview</h4>
                <h5 className="text-white uppercase"></h5>
            </div>
            <div className="overlay bg-opacity-5"></div>
            <img src={parallax_bg11} alt="" className="img-responsive"/> </div>
        </section>

        <div className="clearfix"></div>

        <section>
            <div className="pagenation-holder">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-6 text-right">
                                <div className="pagenation_links"><Link to="/">Home</Link><i> / </i> <Link to="/folioview">Folioview</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <div className="clearfix"></div>

            <section className="sec-padding">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 bmargin">  <br/>
                        <h5> Folioview Pension </h5>
                        <div className="divider-line solid light"></div>
                    <br></br>
                    <p>
                        Especialmente desenhado para fundos de pensão, o Folioview Pension oferece informações vitais na análise de risco e performance da carteira de investimentos das fundações, além de atender todas as exigências normativas da Secretaria de Previdência Complementar (SPC).
                        <br/>
                        <br/>
            
                        Com o Folioview Pension as fundações podem acompanhar a saúde de seus investimentos em cada um de seus gestores, avaliando quem possui o perfil de risco-retorno mais adequado a sua política de investimento. O Folioview Pension permite também uma visão global da carteira, por meio de relatórios de risco e performance consolidados. O Folioview Pension gera todos os relatórios exigidos pela SPC, incluindo o Demonstrativo de Investimentos (DI), o resumo aos participantes da fundação e a Divergência Não Planejada (DNP).
                        <br/>
                        <br/>
            
                        Os relatórios Folioview Pension são disponibilizados pela Internet e podem ser impressos no formato PDF (Adobe Acrobat).
                        <br/>
                        <br/>
            
                        <span className="subtitle">Cobertura</span>
                        <br/>
                        Os relatórios do Folioview Pension permitem uma análise global ou segmentada por renda fixa, renda variável e multimercados e abrangem os seguintes tópicos da carteira de investimentos:
                        <br/>
                        <br/>
            
                        <span className="subtitle">o</span> Informações detalhadas da composição global da carteira<br/>
                        <span className="subtitle">o</span> Value at Risk<br/>
                        <span className="subtitle">o</span> Relatórios de performance ajustadas ao risco<br/>
                        <span className="subtitle">o</span> Indicadores da qualidade das carteiras de renda fixa e variável <br/>
                        <span className="subtitle">o</span> Análise de sensibilidade a fatores de risco<br/>
                        <span className="subtitle">o</span> Testes de stress em crises históricas<br/>
                        <span className="subtitle">o</span> Liquidez e gap atuarial<br/>
                        <span className="subtitle">o</span> Informações agregadas e separadas por gestor<br/>
                        <span className="subtitle">o</span> Informações agregadas e separadas por classe de ativos <br/>
                        <span className="subtitle">o</span> Enquadramento em cada um dos segmentos da 4661
                        <br/>
                        <br/>
            
            
                        <span className="subtitle">Serviços</span><br/>
                        Dentro do Folioview Pension estão incluídos os seguintes serviços agregados:
                        <br/>
                        <br/>
            
            
                        <span className="subtitle">Consolidação de Informações</span> - A <b>NetQuant</b> consolida as informações dos investimentos de todos os gestores das Fundações permitindo a geração dos relatórios do Folioview Pension e o atendimento das exigências da SPC. O sistema desenvolvido pela NetQuant para coletar as informações dos gestores é compatível com os principais formatos de dados disponíveis no mercado e utiliza processos de criptografia para garantir o sigilo destas informações.
                        <br/>
                        <br/>
            
                        <span className="subtitle">DI</span> - A <b>NetQuant</b> gera o DI de seus clientes, no formato exigido pela SPC, contendo todas as informações requeridas pela Resolução 4661 incluindo:
                        <br/>
                        <br/>
            
                        <span className="subtitle">o</span> Listagem detalhada dos investimentos<br/>
                        <span className="subtitle">o</span> Cálculo de Risco<br/>
                        <span className="subtitle">o</span> Despesas de corretagem<br/>
                        <span className="subtitle">o</span> Informações de Rating de Contrapartes<br/>
                        <span className="subtitle">o</span> Enquadramento dos Investimentos
                        <br/>
                        <br/>
            
            
                        <span className="subtitle">Resumo aos Participantes</span> - Outra exigência da Resolução 4661 é o envio do resumo aos participantes das Fundações. A <b>NetQuant</b> gera esses relatórios com as informações requeridas pela Resolução 4661.
                        <br/>
                        <br/>
            
                        <span className="subtitle">Benefícios</span><br/>
                        O Folioview Pension, juntamente com o serviço de consolidação de informações, possibilitam a análise de investimentos sob uma ótica moderna, onde o risco é uma variável tão importante quanto a rentabilidade. Além da análise comparativa de cada gestor, o Folioview Pension oferece uma quantificação independente do valor de mercado de seus investimentos.

            </p>
            <br/>
            </div>

            <div className="col-md-4 bmargin"> <br/>
            <h5>Folioview Insurance</h5>
            <div className="divider-line solid light"></div>
            <br/>
            <p>
                Com o Folioview Insurance as seguradoras podem dimensionar com precisão o risco de suas reservas e acompanhar a saúde de seus investimentos em cada um de seus gestores, avaliando quem possui o perfil de risco-retorno mais adequado a sua política de investimento. Através dos relatórios do Folioview Insurance, as seguradoras podem selecionar quantitativamente seus fundos de investimentos. O Folioview Insurance permite também uma visão global da carteira, por meio de relatórios de risco e performance consolidados.
                <br/>
                <br/>
                Os relatórios Folioview Insurance são disponibilizados pela Internet e podem ser impressos no formato PDF (Adobe Acrobat).
                <br/>
                <br/>
                <span className="subtitle">Cobertura</span><br/>
                Os relatórios do Folioview Insurance permitem uma análise global ou segmentada por renda fixa e renda variada e abrangem os seguintes tópicos da carteira de investimentos:
                <br/>
                <br/>
                <span className="subtitle">o</span> Informações detalhadas da composição global da carteira <br/>
                <span className="subtitle">o</span> Value at Risk <br/>
                <span className="subtitle">o</span> Relatórios de performance ajustadas ao risco <br/>
                <span className="subtitle">o</span> Indicadores da qualidade das carteiras de renda fixa e variável <br/>
                <span className="subtitle">o</span> Análise de sensibilidade a fatores de risco <br/>
                <span className="subtitle">o</span> Testes de stress em crises históricas <br/>
                <span className="subtitle">o</span> Liquidez <br/>
                <span className="subtitle">o</span> Informações agregadas e separadas por gestor <br/>
                <span className="subtitle">o</span> Informações agregadas e separadas por classe de ativos 
                <br/>
                <br/>
                <span className="subtitle">Serviços</span><br/>
                Dentro do Folioview Insurance estão incluídos os seguintes serviços agregados:
                <br/>
                <br/>
                <span className="subtitle">Consolidação de Informações</span> - A <b>NetQuant</b> consolida as informações dos investimentos de todos os gestores das Fundações permitindo a geração dos relatórios do Folioview Insurance. O sistema desenvolvido pela <b>NetQuant</b>  para coletar as informações dos gestores é compatível com os principais formatos de dados disponíveis no mercado e utiliza processos de criptografia para garantir o sigilo destas informações.
                <br/>
                <br/>
                <span className="subtitle">Benefícios</span> <br/>
                O Folioview Insurance, juntamente com o serviço de consolidação de informações, possibilitam a análise de investimentos sob uma ótica moderna, onde o risco é uma variável tão importante quanto a rentabilidade. Além de quantificar o real risco das reservas das seguradoras, o Folioview Insurance oferece uma quantificação independente do valor de mercado de seus investimentos.
                <br/>
                <br/>

            </p>
            </div>
            <div className="col-md-4 bmargin">  <br/>
                    <h5>Pension Club</h5>
                    <div className="divider-line solid light"></div>
                    <br></br>
                    <p>

                        O Pension Club foi criado em parceria com a Willis Towers Watson, com o objetivo de fornecer, às fundações associadas ao clube, mecanismos de comparação para a avaliação e formulação de suas políticas de investimento de longo prazo.
                        <br/>
                        <br/>
                        O clube promove debates periódicos entre seus associados onde são discutidos as condições do mercado e os resultados obtidos pelos diversos gestores das fundações. O Pension Club fornece aos seus associados relatórios comparativos de investimento, onde são avaliadas a performance das entidades e de seus gestores utilizando o critério de ranking e diagramas de risco/retorno. Todas as informações utilizadas para formular os relatórios comparativos permanecem anônimas.
                        <br/>
                        <br/>
                        <span className="subtitle">Cobertura</span> <br/>
                        O Pension Club oferece à seus associados:
                        <br/>
                        <br/>

                        <span className="subtitle">Reuniões entre as Fundações </span>- Nas reuniões são apresentados os resultados observados no clube relativos às entidades e seus gestores. As reuniões contam com a presença de especialistas da área que discutem as conjunturas e tendências do mercado, permitindo que os associados do Pension Club possam discutir e reavaliar suas diretrizes de investimento.
                        <br/>
                        <br/>
                        <span className="subtitle">Relatórios Comparativos de Investimento </span>- Com os relatórios comparativos do Pension Club, as entidades podem comparar sua performance e de seus gestores baseando-se nas seguintes informações:
                        <br/>
                        <br/>
                        <span className="subtitle">o</span> Estatísticas de retorno <br/>
                        <span className="subtitle">o</span> Relatórios segmentados por renda fixa, variável e multimercados <br/>
                        <span className="subtitle">o</span> Estatísticas de risco <br/>
                        <span className="subtitle">o</span> Diagramas comparativos de risco-retorno <br/>
                        <span className="subtitle">o</span> Gráficos evolutivos comparativos de risco e de retorno  
                        <br/>
                        <br/>

                        <span className="subtitle">Folioview Pension</span> - Os associados do Pension Club recebem mensalmente o Folioview Pension – os relatórios de análise de risco e investimentos da <b>NetQuant</b> para fundos de pensão.
                        <br/>
                        <br/>
                        <span className="subtitle">Benefícios</span><br/>
                        Por meio dos debates promovidos e dos relatórios comparativos, as fundações associadas ao Pension Club obtêm uma noção exata da performance de seus investimentos em relação ao mercado financeiro e outras fundações.

                    </p>
                    </div>

                </div>
                <br></br>
            <br></br>
        </div>
        </section>
        <div className="clearfix"></div>

        <section className="section-copyrights sec-moreless-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12"> <span>Copyright © 2020 www.netquant.com.br All rights reserved.</span></div>
                </div>
            </div>
        </section>
        <div className="clearfix"></div>

        <a href="#" className="scrollup orange2"></a>

        <div className="clearfix"></div>
        <a href="#" className="scrollup"></a>

        <ScrollToTop showUnder={160}>
                    <img src={up_arrow} alt="up_arrow" />
        </ScrollToTop>

    </div>
      
    
    )
}


export default Folioview;