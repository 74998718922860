import React, { useState, useEffect } from 'react';

import api from '../../../services/api'

import { Link } from 'react-router-dom';

import swal from 'sweetalert';

function Index(){

    const [users, setUsers] = useState([]);

    useEffect(() => {
        api.get(`/users`)
        .then(resp => { 
            setUsers(resp.data)
        })
        .catch(e => {
          console.log(e);
        });

    },[]);

    const deleteUser = (userId) => {
        api.delete(`/users/${userId}`)
        .then(resp => { 
            console.log(resp.data.success)
            window.location.reload();
        })
        .catch(e => {
            console.log(e.response.data.error)
        });

    };

    const handleDeleteUser = (userLogin, userId) => {
        swal(`Tem certeza de que deseja deletar o usuário ${userLogin}?`, {
                buttons: {
                cancel: "Cancelar",
                catch: {
                    text: "Confirmar",
                    value: "Confirmar",
                }
                },
            })
            .then(async (value) => {
                switch (value) {
                case "Confirmar":
                    try{
                       await deleteUser(userId);
                    }catch(err){
                        swal("Erro!", "Erro ao deletar o usuário", "error");
                    }
                    break;
                default:
                    
                }
            });
    }

    return(
        <div className="container"> 
        <br></br> 
            <div className="row"> 
                <Link to="/admin/users/new" ><button type="button" className="btn btn-primary">Criar usuário</button></Link>
                <br></br><br></br>
                <div className="table-responsive"> 
                    <table className="table"> 
                        <thead className="thead-dark"> 
                            <tr> 
                                <th>Nome</th> 
                                <th>Login</th> 
                                {/*<th>Email</th>*/} 
                                <th>Ações</th> 
                            </tr> 
                        </thead> 
                        <tbody> 
                        {users && users.map((user, index) => 
                            <tr key={index} className="text-center"> 
                                <td>{user.name} &emsp;</td>  
                                <td>{user.login} &emsp;</td> 
                                {/*<td>{user.email}</td>*/}
                                <td className="actions"> 
                                <Link to={`/admin/users/edit/${user.id}`}><button type="button" className="btn btn-info btn-sm">Editar</button></Link>&nbsp;
                                <Link to={`/admin/users/password/${user.id}`}><button type="button" className="btn btn-warning btn-sm">Alterar Senha</button></Link>&nbsp;
                                <button type="button" className="btn btn-danger btn-sm" disabled={user.admin ? true : false} onClick={() => handleDeleteUser(user.login,user.id)}>Deletar</button>
                                </td> 
                            </tr>
                        )} 
                        </tbody> 
                    </table> 
                </div>
            </div>       
        </div>
    )
}

export default Index;


