import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../services/api';


function Update(props){

    const [user, setUser] = useState(null);
    const [msg, setMsg] = useState(null)

    useEffect(() => {
        api.get(`/users/${props.match.params.id}`)
        .then(resp => { 
            setUser(resp.data)
        })
        .catch(e => {
          console.log(e.response.data.error);
        });

    }, [props.match.params.id])

    const handleUserUpdate = e => {
        e.preventDefault();
        api.put(`/users/${props.match.params.id}`, {user})
        .then(resp => { 
            setMsg(resp.data.success);
        })
        .catch(e => {
            setMsg(e.response.data.error);
        });


    }

    return(
        <div className="login-form">
            {user && <form id="createUserForm" onSubmit={handleUserUpdate}>
                    <center><h3><i className="fa fa-user fa-4x"></i></h3></center>
                    <center> <h2 className="text-center">Alteração de Usuário</h2></center>
                    <center><p>Alterando o usuário {user.login}.</p></center>
                    <div className="form-group">  
                        Nome:
                        <input
                        type="text"
                        placeholder="Nome"
                        className="form-control"
                        value={user.name}
                        required
                        onChange={e => setUser({...user, name: e.target.value })}
                        />
                    </div>
                   {/*} <div className="form-group"> 
                        Email: 
                        <input
                        type="Email"
                        placeholder="Email"
                        className="form-control"
                        value={user.email}
                        required
                        onChange={e => setUser({...user, email: e.target.value })}
                        />
                    </div>
                    */}
                    <div className="form-group">  
                        Login:
                        <input
                        type="text"
                        placeholder="Login"
                        className="form-control"
                        value={user.login}
                        required
                        onChange={e => setUser({...user, login: e.target.value })}
                        />
                    </div> 
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" type="submit">Atualizar</button>
                    </div>
                    <hr />
                    <center>{msg}</center>
                    <center><Link to="/admin/users"> Cancelar </Link></center>
                </form>
            }
        </div>
    )
}

export default Update;