import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loading = () => {
  return (
    <div className="loading-reports">
      <strong>Carregando relatórios </strong>
      <AiOutlineLoading3Quarters size={15} color="#727272" />
    </div>
  );
};

export default Loading;
