import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import  ScrollToTop  from  "react-scroll-up";

import api from '../../services/api';

import { getNumMonth } from '../../common'


import parallax_bg11 from '../../assets/images/parallax-bg11.jpg';
import logo_nq from '../../assets/images/logo_nq.png';
import up_arrow from '../../assets/images/up_arrow_round.png';



function Market(){
    
    const [marketSummaries, setMarketSummaries] = useState([]);
    const [analysis, setAnalysis] = useState([]);

    useEffect(() =>{
        api.get('market_summaries')
        .then(resp => { 
            let sortedMarketSummaries = sortMarketSummaries(resp.data.market_summaries)
            setMarketSummaries(sortedMarketSummaries)
        })
        .catch(e => {
          console.log(e);
        });
    
    },[])

    useEffect(() =>{
        api.get('analysis')
        .then(resp => { 
            let sortedAnalysis = sortAnalysis(resp.data.analysis)
            setAnalysis(sortedAnalysis)
        })
        .catch(e => {
          console.log(e);
        });
    
    },[])

    const downloadMarketSummary = (marketSummaryName) =>{
        api.get(`download_market_summary/${marketSummaryName}`, {responseType: 'arraybuffer'} )
        .then(resp => { 
            //Compatible with Explorer.
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${marketSummaryName}.pdf`);
            }
            else{
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${marketSummaryName}.pdf`); 
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(e => {
          console.log(e);
        }); 
    }

    const sortMarketSummaries = (marketSummaries) => {
        let marketSummariesNames = [{}];
        let nonDigitpatt = /[^0-9]/g;
        let digitPatt = /[0-9]/g;

        marketSummaries.forEach(marketSummary => {
            let nonDigit = marketSummary.match(nonDigitpatt).join("");
            let digit = marketSummary.match(digitPatt).join("");

            let month =  nonDigit.split("_")[1];
            let year = digit;

            marketSummariesNames.push({"originalName": marketSummary, "nameToShow": month+"/"+digit, "valueToSort": parseInt(year + getNumMonth(month)) })
            
        });
        marketSummariesNames =  marketSummariesNames.sort(function(a, b){return a.valueToSort - b.valueToSort}).reverse();
        return marketSummariesNames;

    }

    const downloadAnalyze = (analyzeName) =>{
        api.get(`download_analyze/${analyzeName}`, {responseType: 'arraybuffer'} )
        .then(resp => { 
            //Compatible with Explorer.
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `${analyzeName}.pdf`);
            }
            else{
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${analyzeName}.pdf`); 
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(e => {
          console.log(e);
        }); 
    }

    const sortAnalysis = (analysis) => {
        let analysisNames = [{}];
        let d = new Date();

        analysis.forEach(analyze => {
 	        d.setFullYear(2000 + parseInt(analyze.split("_")[1]));
            let year = d.getFullYear();
            let nameToShow = analyze.split("_")[2].charAt(1) + "o" + " Trimestre" + " - " + year;

            analysisNames.push({"originalName": analyze, "nameToShow": nameToShow, 
                "valueToSort": parseInt(year.toString() + analyze.split("_")[2].charAt(1))})  
        });
        analysisNames =  analysisNames.sort(function(a, b){return a.valueToSort - b.valueToSort}).reverse();
        return analysisNames;
    }
    
    return(
        <div>
            <div className="site_wrapper">
                    <div className="topbar light">
                        <div className="container">
                            <div className="topbar-left-items">
                                <div className="margin-top1"></div>
                                    <ul className="toplist toppadding pull-left paddtop1">
                                        <li className="rightl"></li>
                                    </ul>
                                </div>

                                <div className="topbar-right-items pull-right">
                                    <ul className="toplist toppadding"></ul>
                                </div>
                            </div>
                        </div>

                        <div className="clearfix"></div>
            
                    </div>
                
                    <div className="clearfix"></div>
                    
                    
                    <div id="header">
                        <div className="container">
                            <div className="navbar navbar-default yamm">
                                <div className="navbar-header">
                                    <button type="button" data-toggle="collapse" data-target="#navbar-collapse-grid" className="navbar-toggle two three"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                                    <Link to="/" className="navbar-brand"><img src={logo_nq} alt=""/></Link>
                                </div><br/>
                                <div id="navbar-collapse-grid" className="navbar-collapse collapse pull-right">
                                    <ul className="nav navbar-nav">
                                        <li className="dropdown"><Link to="/" className="dropdown-toggle ">Home</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="clearfix"></div>
                
                <section>
                    <div className="header-inner two">
                        <div className="inner text-center">
                            <h4 className="title text-white uppercase">Resumo de Mercado</h4>
                            <h5 className="text-white uppercase"></h5>
                        </div>
                        <div className="overlay bg-opacity-5"></div>
                        <img src={parallax_bg11} alt="" className="img-responsive"/> 
                    </div>
                </section>

                <div className="clearfix"></div>
                
                <section>
                    <div className="pagenation-holder">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-md-6 text-right">
                                        <div className="pagenation_links"><Link to="/">Home</Link><i> / </i> <Link to="/market">Resumo de Mercado</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="clearfix"></div>
                
                <section className="sec-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-centered text-center">
                                <h4 className="text-orange-2 font-weight-3"> <strong>Estudos</strong> <p></p>A NetQuant disponibiliza estudos de interesse do mercado: 

                                </h4>
                            </div>
                                <br/>
                                <br/>
                                <div className="divider-line solid light"></div>
                                <br/>
                                <br/>
                                <div className="col-md-4 bmargin"> <br/>
                                    <h5><span className="subtitle">o</span> Resumo de Mercado: </h5>
                                    <div className="divider-line solid light"></div>
                                    <br></br>
                                    {marketSummaries && 
                                        marketSummaries.map((marketSummary, index) => {
                                            return <div key={index}><a key={index} href="javascript:void(0)" 
                                            onClick={ () => downloadMarketSummary(marketSummary.originalName)}>{marketSummary.nameToShow}</a><br/> </div>
                                        })
                                    }
                                    <br/>
                                </div>

                        
                                <div className="col-md-4 bmargin"> <br/>
                                    <h5><span className="subtitle">o</span> Análise Político-econômica:</h5>
                                    <div className="divider-line solid light"></div>
                                    <br/>
                                
                                    {analysis && 
                                        analysis.map((analyze, index) => {
                                            return  <div key={index}>
                                                        <a key={index} href="javascript:void(0)"  onClick={() => downloadAnalyze(analyze.originalName)}>{analyze.nameToShow}</a><br/> 
                                                    </div>
                                        })
                                    }
                                </div>
                        </div>
                            <br></br>
                            <div className="divider-line solid light"></div>
                    </div>
                </section>
                

                <div className="clearfix"></div>
                
                

                <div className="clearfix"></div>
                
                <section className="section-copyrights sec-moreless-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12"> <span>Copyright © 2020 www.netquant.com.br All rights reserved.</span></div>
                        </div>
                    </div>
                </section>

                <div className="clearfix"></div>

                <ScrollToTop showUnder={160}>
                    <img src={up_arrow} alt="up_arrow" />
                </ScrollToTop>
    </div>

    )
}

export default Market;