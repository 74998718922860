import jwt from "jsonwebtoken";

import jwtSecret  from './jwtSecret';
import { getToken, removeToken } from './credentials'

function isAuthenticated() {
    const token = getToken();
    
    if (!token) return false;
  
    return jwt.verify(token, jwtSecret, (err, decoded) => {

      if (err){
        removeToken();
        return false;
      }else{
        return true;
      }
    });
  }

export default isAuthenticated;