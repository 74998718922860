import React, {useState, useEffect} from 'react';

import { getCredentials} from '../../auth/credentials'
import api from '../../services/api';


function Reports(){
    
    const [dateOptions, setDateOptions] = useState([]);
    const [fileOptions, setFileOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState(null);

    const [checkedAll, setCheckedAll] = useState(true);

    const [isDownloading, setIsDownloading] = useState(false);
   
    const userCode = getCredentials().user_login;

    const checkAll = () => {
        let r = document.getElementsByName("reports");
        let el =  document.getElementById("checkAll");
        el.checked = true;
        for (let i = 0; i < r.length; i++) {
            r[i].checked = true;
        }
        setCheckedAll(true);
    };

    const unCheckAll = () => {
        let r = document.getElementsByName("reports");
        let el =  document.getElementById("checkAll");
        el.checked = false;
        for (let i = 0; i < r.length; i++) {
            r[i].checked = false;
        }
        setCheckedAll(false);
    };
 
    const getFiles = (date) => {
        api.get(`/bucketfiles/${date}`)
        .then(resp => { 
          setFileOptions(resp.data.files);
          setSelectedDate(date);
          checkAll();
        })
        .catch(e => {
          console.log(e);
        });

    };

     
    useEffect(() => {
        api.get(`/bucketdates`)
        .then(resp => { 
            const dates = resp.data.dates.sort(function(a,b){ 
                a = a.split("-");
                b = b.split("-");
                return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1);
            }).reverse();
            setDateOptions(dates);
            getFiles(dates[0]);
        })
        .catch(e => {
          console.log(e);
        }); 
    },[]);


    const handleFilesDownload = () => {
        let selectedFiles = [];
        let r = document.getElementsByName("reports");
        for (let i = 0; i < r.length; i++) {
            if (r[i].checked){
                selectedFiles.push(r[i].value)
            }
        }

       // let files = fileOptions.length -1 === selectedFiles.length ? [`${userCode}.zip`] : selectedFiles;
        let files = selectedFiles;
        let date = selectedDate;
        api.post('/downloadfiles',{date, files}, {responseType: 'arraybuffer'})
        .then(resp => { 
            //Compatible with Explorer.
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(new Blob([resp.data]), `relatorios-${userCode}-${selectedDate}.zip`);
            }
            else{
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `relatorios-${userCode}-${selectedDate}.zip`); 
                document.body.appendChild(link);
                link.click();
            }
            setIsDownloading(false);
        })
        .catch(e => {
          console.log(e.response);
        });

    };

    //Compatible with Explorer.
    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(searchString, position) {
            var subjectString = this.toString();
            if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
              position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        };
      }
     
    return (
        <div className="container float-left">
            <h3 className="mt-4"> Relatórios NetQuant  </h3>
                         
            {isDownloading && <center><h5>Preparando Download...</h5></center>}
           
                <div className="row">
                    <div className="col-xs-2">
                        <select className="form-control" onChange={(e) => getFiles(e.target.value)}>
                            {dateOptions && dateOptions.map((date) => <option key={date}  value={date} >{date}</option>)}
                        </select>
                    </div>
                    <div className="col-xs-4">
                        <a href="#" onClick={() => {handleFilesDownload(); setIsDownloading(true)}}>
                        <i className="fa fa-download fa-2x pull-right" title="Baixar relatórios"></i>
                        </a> 
                    </div>
                </div><br></br>
               
                <div>
                    <label>
                        <input id="checkAll" name="checkAll" type="checkbox" onClick={() => checkedAll ? unCheckAll() : checkAll()}/> 
                    Marcar/Desmarcar todos</label><br></br>

                        {fileOptions &&  fileOptions.map((file) => {
                            return file.endsWith(".zip") ? null :
                            <div key={file}>
                                <label key={file}>
                                    <input key={file} name="reports" type="checkbox" value={file} /> 
                                {file}</label><br></br>
                            </div>
                        })}

                </div><br/>
        </div>
    )
}

export default Reports;